import {ChallengeSecrets, getChallengeSecrets} from "./ChallengeSecrets"
import axios from 'axios';
import qs from 'qs';
import Url from 'url-parse';
import { AppAuthState } from './AppAuthFlowState';
import { AuthRedirectQueryParams, Env as Env, OpenIdConfig, TokenResponse } from "./types";
import { Environments } from "./Constants";
import { getEnv } from "src/app/config/env";

const env = getEnv();



export class AuthCodeFlow{
    constructor(
        challengeSecrestsProvider?: ChallengeSecrets,
    ){
        this.challengeSecrestsProvider = challengeSecrestsProvider
    }

    challengeSecrestsProvider?: ChallengeSecrets
    authFlowState: AppAuthState = new AppAuthState();
    async startLoginProcess(
        extraParams: any = {},
      ) {
        console.log("Env: ", env)
        const authenticationUrl = await this.getAuthenticationUrl(env, { ...extraParams });
        console.log(authenticationUrl)
        //const response = await (WebBrowser.openAuthSessionAsync(authenticationUrl, ))
        const flowState = this.authFlowState.getFlowState();
        localStorage.setItem("code_verifier", flowState?.codeVerifier ?? "");
        localStorage.setItem("state", flowState?.state ?? "");
        window.location.href = authenticationUrl;

        // if (response.type === 'cancel' || response.type === 'dismiss') {
        //     console.log('User cancelled login flow');
        // }
    }
    
    async startRegisterProcess(
        registrationType: 'create-account' | 'request-access',
        extraParams: any = {},
      ) {
        const authenticationUrl = await this.getAuthenticationUrl(env, { ...extraParams, registrationType });
        console.log(authenticationUrl)
        // const response = await (WebBrowser.openAuthSessionAsync(authenticationUrl, ))
        // if (response.type === 'cancel' || response.type === 'dismiss') {
        //     console.log('User cancelled login flow');
        // }
    }

    private async getAuthenticationUrl(env: Env, extraAuthenticatorQueryParams: any): Promise<string> {
        const { codeChallenge, codeVerifier, state } = this.challengeSecrestsProvider ?? await getChallengeSecrets();

        this.authFlowState.setFlowState({
          codeVerifier,
          state,
          env
        });
        const configuration = Environments[env];
        const { ClientID, RedirectUri, OAuthScopes } = configuration;
        const openIdConfigResponse = await this.fetchOpenIdConfig(env);
        const authenticatorUrl = openIdConfigResponse?.authorization_endpoint;

        const queryParams = {
            redirect_uri: RedirectUri,
            client_id: ClientID,
            response_type: 'code',
            scope: OAuthScopes,
            prompt: 'login',
            state,
            locale: 'en',
            response_mode: 'query',
            code_challenge: codeChallenge,
            code_challenge_method: 'S256',
            ...extraAuthenticatorQueryParams,
          };

        const authenticatorUrlParams = new URLSearchParams(queryParams);

        return `${authenticatorUrl}?${authenticatorUrlParams.toString()}`;
    }

    async startLogoutProcess() {
      const configuration = Environments[env];
      const { RedirectUri } = configuration;
      const openIdConfigResponse = await this.fetchOpenIdConfig(env);
      const authenticatorUrl = openIdConfigResponse?.end_session_endpoint;
      const idToken = localStorage.getItem("id_token") ?? "";
      const queryParams = {
        id_token_hint: idToken,
        post_logout_redirect_uri: `${RedirectUri}`,
      };
  
      const authenticatorUrlParams = new URLSearchParams(queryParams);
  
      const logoutUrl = `${authenticatorUrl}?${authenticatorUrlParams.toString()}`;
      localStorage.clear()
      console.log(logoutUrl);
      //window.location.href = logoutUrl;
    }

    async handleAuthenticationCallback(url: string, authenticate: (arg0: any) => void, logout: () => void) {
      
      const configuration = Environments[env];
      const { RedirectUri  } = configuration;
  
      const isSignIn = url.indexOf(`${RedirectUri}`) === 0;
      const isSignOut = url.indexOf(`${RedirectUri}callback/logout`) === 0;
  
      console.log('Authorization url callback received', url, isSignIn, isSignOut);
  
      if (isSignOut) {
        console.log('signout');
        
        logout();
        return;
      }
  
      const parsedUrl = new Url(url);
      const queryParams = qs.parse(parsedUrl.query.replace('?', '')) as AuthRedirectQueryParams;
      console.log(queryParams);
  
      if (queryParams.redirect_reason === 'REQUEST_ACCESS') {
        
        return;
      }
  
      if (queryParams.redirect_reason === 'CREATE_ACCOUNT') {
        authenticate(this.tokenInfoFromTokenResponse(queryParams));
  
        return;
      }
  
      const tokenResponse = await this.exchangeAuthorizationCode(queryParams.code, queryParams.state, queryParams.error);
  
      authenticate(this.tokenInfoFromTokenResponse(tokenResponse));

    }

    async exchangeAuthorizationCode(paramCode:string, paramState:string, paramError?:string): Promise<any> {
        //const flowState = this.authFlowState.getFlowState();
        const codeVerifier = localStorage.getItem("code_verifier");
        const state = localStorage.getItem("state");

        if (paramError) {
          throw new Error(`There has been an error while signin: ${paramError}`);
        }
        if (!paramCode) {
          throw new Error('There was no code returned from IdentityServer, aborting login flow.');
        }
        if (state !== paramState) {
          throw new Error('There has been a state mismatch.');
        }
    
        const configuration = Environments[env];
    
        const { ClientID, RedirectUri } = configuration;
        const openIdConfigResponse = await this.fetchOpenIdConfig(env);

        const data = qs.stringify({
          grant_type: 'authorization_code',
          client_id: ClientID,
          code: paramCode,
          code_verifier: codeVerifier,
          redirect_uri: RedirectUri,
        });
  
        const tokenUrl = openIdConfigResponse?.token_endpoint || '';
    
        const tokenResponse = await axios.post<TokenResponse>(tokenUrl, data, {
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
        });
        //const tokenData = this.tokenInfoFromTokenResponse(tokenResponse.data)

        return tokenResponse.data;
      }

      async fetchOpenIdConfig(env:Env): Promise<OpenIdConfig | undefined> {
        const configuration = Environments[env];
        const { AuthenticatorURL } = configuration;
    
        const openIdConfigResponse = await axios.get<OpenIdConfig | undefined>(
          `${AuthenticatorURL}/.well-known/openid-configuration`,
        );
        return openIdConfigResponse.data;
      }
     tokenInfoFromTokenResponse(tokenResponse: any): any {
        return {
          accessToken: tokenResponse.access_token,
          refreshToken: tokenResponse.refresh_token,
          idToken: tokenResponse.id_token,
          expiresIn: tokenResponse.expires_in,
        };
      }
    
}