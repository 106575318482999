import { useContext, useEffect, useState } from "react";
import { useGetCustmerSegmentQuery } from "../../../../../generated/graphql";
import { AsyncSelect } from "../../async-select/AsyncSelect";
import { AsyncSelectData } from "../../async-select/AsyncSelectData";
import { useTranslation } from "react-i18next";
import AuthContext from "src/app/contexts/auth-context";

interface FormikFormSelectProps {
  fieldName: string;
  labelName?: string;
  intialId?: number;
  formik;
  type?: string;
  showLabel?: boolean;
}

const FormikCustomerSegementFormSelect = (props: FormikFormSelectProps) => {
  const userService = useContext(AuthContext);

  
  const { data: customerSegmentData, loading: loadingCompanies } =
    useGetCustmerSegmentQuery({
      variables: {
        companyId: Number(
          userService.authContextData?.currentUser?.location?.company?.id
        ),
      },
    });
  const formikSetFieldValue = props.formik.setFieldValue;
  const { t } = useTranslation();

  const [initialCompany, setInitialCustomerSegment] =
    useState<AsyncSelectData>();

  useEffect(() => {
    if (customerSegmentData?.customerSegments?.nodes) {
      let customerSegment = customerSegmentData?.customerSegments?.nodes?.find(
        (i) => i.id === props.intialId
      );
      if (customerSegment) {
        let customerSegmentSelectData = new AsyncSelectData({
          title: customerSegment?.name,
          value: customerSegment?.id,
        });
        setInitialCustomerSegment(customerSegmentSelectData);
        setTimeout(() => {
          formikSetFieldValue(props.fieldName, customerSegment?.id ?? 0);
        }, 200);
      } else {
        setInitialCustomerSegment(undefined);
        formikSetFieldValue(props.fieldName, 0);
      }
    }
  }, [
    setInitialCustomerSegment,
    customerSegmentData,
    formikSetFieldValue,
    props.fieldName,
    props.intialId,
  ]);

  const selectedCustomerSegmentChanged = (data: AsyncSelectData) => {
    formikSetFieldValue(props.fieldName, data.value);
  };

  return (
    <>
      <div className={`mt-3`}>
        <AsyncSelect
          label={props.showLabel ? t("modules.formik.customer-segment") : ""}
          onItemSelected={selectedCustomerSegmentChanged}
          onOpen={() => {
            console.log("");
          }}
          defaultSelectOption={initialCompany}
          data={customerSegmentData?.customerSegments?.nodes?.map(
            (i) =>
              new AsyncSelectData({
                title: i.name,
                value: i.id,
              })
          )}
          loadingData={loadingCompanies}
          hasError={Boolean(
            props.formik.errors[props.fieldName] &&
              props.formik.touched[props.fieldName]
          )}
          className={`w-full`}
        />
      </div>
    </>
  );
};

export { FormikCustomerSegementFormSelect };
