import { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { AsyncSelectData } from "./AsyncSelectData";
import { IAsyncSelect } from "./IAsyncSelect";
import React from "react";

const AsyncSelect = React.forwardRef(
  (
    {
      className,
      onOpen = () => {
        console.log("open");
      },
      data,
      loadingData = true,
      placeholder = "",
      onItemSelected,
      hasError = false,
      label = undefined,
      defaultSelectOption = null,
      inputValueChanged = (_evt: any, _value: any) => {
        console.log("open");
      },
      keyUp = (_evt) => {
        console.log("key up");
      },
      disabled = false,
    }: IAsyncSelect,
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<readonly AsyncSelectData[]>([]);
    const [selectedOption, setSelectedOption] =
      useState<AsyncSelectData | null>(null);
    const [_defaultSelectedOption, _setDefaultSelectedOption] = useState<
      AsyncSelectData | null | undefined
    >(null);
    const [defaultAlreadySet, setDefaultAlreadySet] = useState(false);
    // const [clearController,setClearController] = useState({});
    // const autoCompleRef = useRef(null);

    // const clear = useCallback(()=>{
    //   const ele = (autoCompleRef?.current as any).getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    //
    //   if (ele) ele.click();
    // },[]);

    // useEffect(()=>{
    //   (
    //     autoCompleRef?.current as any as HTMLElement
    //   )?.addEventListener('mouseenter',()=>{
    //     setClearController({});
    //   });
    // },[]);

    // useEffect(() => {
    //   const ele = (
    //     autoCompleRef?.current as any as HTMLElement
    //   ).getElementsByClassName("MuiAutocomplete-clearIndicator")[0];
    //   console.log("important controller",clearController);
    //   if (ele){
    //     ele.removeEventListener("click",onClear);
    //     ele.addEventListener("click", onClear);
    //   }
    // }, [onClear,clearController]);

    useEffect(() => {
      if (defaultSelectOption) {
        _setDefaultSelectedOption(defaultSelectOption);
        return;
      }
    }, [data, _setDefaultSelectedOption, defaultSelectOption]);

    useEffect(() => {
      if (data) setOptions(data ?? []);
    }, [data]);

    useEffect(() => {
      if (open) {
        onOpen();
      }
    }, [open, onOpen]);

    useEffect(() => {
      if (!selectedOption && !defaultAlreadySet && defaultSelectOption) {
        setSelectedOption(defaultSelectOption);
        setDefaultAlreadySet(true);
      }
    }, [defaultSelectOption, selectedOption, defaultAlreadySet]);

    const selectedItemChanged = useCallback(
      (evt: any, value: any) => {
        if (onItemSelected) {
          onItemSelected(value as AsyncSelectData);
          setSelectedOption(value as AsyncSelectData);
        }
      },
      [onItemSelected, setSelectedOption]
    );

    return (
      <Autocomplete
        value={selectedOption}
        ref={ref}
        disabled={disabled}
        handleHomeEndKeys={true}
        filterOptions={(x) => x}
        onChange={selectedItemChanged}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `${hasError ? "red" : ""}`,
          },
        }}
        className={`${className} w-full`}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onKeyUp={keyUp}
        onClose={() => {
          setOpen(false);
        }}
        defaultValue={_defaultSelectedOption}
        onInputChange={(event, newInputValue) => {
          inputValueChanged?.(event, newInputValue);
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) =>
          `${option.title} ${option.labelMarker ?? ""}`
        }
        options={options}
        loading={loadingData}
        renderInput={(params) => (
          <TextField
            placeholder={placeholder}
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingData ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            label={label}
          />
        )}
        renderOption={(props, option) => {
          if(!option.enabled){
            props.onClick = (evt)=>{
              evt.preventDefault();
              evt.stopPropagation();
            };
          }
          return (
            <li className={`hover:!bg-gray-500 ${!option.enabled ? 'pointer-events-none' : ''}`} style={{backgroundColor:option.enabled?"":"#e5e5e5",color:option.enabled?"black":"#acacac"}} title={option.tooltip} {...props} key={option.value}>
              <pre>{option.title}</pre>
              {option.labelMarker && (
                <div style={{ color: "red" }}>{option.labelMarker}</div>
              )}
            </li>
          );
        }}
      />
    );
  }
);

export { AsyncSelect, AsyncSelectData };
