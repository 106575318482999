import { AuthFlowState } from "./types";

export class AppAuthState {
    private static flowState: AuthFlowState | undefined;
  
    setFlowState(state: AuthFlowState): void {
        AppAuthState.flowState = state;
    }
  
    getFlowState(): AuthFlowState | undefined {
      return AppAuthState.flowState;
    }
}