import {jwtDecode} from 'jwt-decode';
export interface ClaimsEntity {
    nbf: number;
    exp: number;
    iss: string;
    aud: string;
    sid?: string;
    jti?: string;
    client_id: string;
    sub: string;
    auth_time: number;
    idp: string;
    given_name: string;
    user_id: string;
    role: string;
    email: string;
    phone: string;
    country_code: string;
    organization_units: string;
    isPasswordChangeRequired: string;
    isTestUser: string;
    scope: string[];
    amr: string[];
  }
  
  export const getAuthClaimsEntity = (accessToken?: string) => {
    if (!accessToken) {
      return null;
    }
  
    try {
      return jwtDecode<ClaimsEntity>(accessToken as string); // fix-ts-2022 validate and proceed only if defined
    } catch (error) {
      console.debug(`Could decode access token ${error}`);
      return null;
    }
  };
