import { useContext, useEffect, useState } from "react";
import { useGetCompaniesQuery } from "../../../../../generated/graphql";
import { AsyncSelect } from "../../async-select/AsyncSelect";
import { AsyncSelectData } from "../../async-select/AsyncSelectData";
import { useTranslation } from "react-i18next";
import AuthContext from "src/app/contexts/auth-context";

interface FormikFormSelectProps {
  fieldName: string;
  labelName?: string;
  intialId?: number;
  formik;
  type?: string;
  showLabel?:boolean;
}

const FormikCompanyFormSelect = (props: FormikFormSelectProps) => {
  const userService = useContext(AuthContext);
  const { data: companies, loading: loadingCompanies } = useGetCompaniesQuery(
    {
      variables: {
        countryId: Number(userService.authContextData?.currentUser?.company?.countryId),
      }
    }
  );
  const formikSetFieldValue = props.formik.setFieldValue;
  const { t } = useTranslation();

  const [initialCompany, setInitialCompany] = useState<AsyncSelectData>();

  useEffect(() => {
    if (companies?.companies) {
      console.log(companies?.companies);
      let company = companies?.companies?.nodes?.find(
        (i) => i.id === props.intialId
      );
      if (company) {
        let companySelectData = new AsyncSelectData({
          title: company?.name,
          value: company?.id,
        });
        setInitialCompany(companySelectData);
        setTimeout(()=>{
          formikSetFieldValue(props.fieldName, company?.id??0);
        },200);
      } else {
        setInitialCompany(undefined);
        formikSetFieldValue(props.fieldName, 0);
      }
    }
  }, [
    setInitialCompany,
    companies,
    formikSetFieldValue,
    props.fieldName,
    props.intialId,
  ]);

  const selectedCompanyChanged = (data: AsyncSelectData) => {
    formikSetFieldValue(props.fieldName, data.value);
  };


    return (
      <>
        <div className={`mt-3`}>
          <AsyncSelect
            label={props.showLabel ? t("modules.formik.company-label") : ""}
            onItemSelected={selectedCompanyChanged}
            onOpen={() => {console.log('')}}
            defaultSelectOption={initialCompany}
            data={companies?.companies?.nodes?.map(
              (i) =>
                new AsyncSelectData({
                  title: i.name,
                  value: i.id,
                })
            )}
            loadingData={loadingCompanies}
            hasError={Boolean(
              props.formik.errors[props.fieldName] &&
                props.formik.touched[props.fieldName]
            )}
            className={`w-full`}
          />
        </div>
      </>
    );
};

export { FormikCompanyFormSelect };
