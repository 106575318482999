import { useEffect, useState } from "react";
import { useGetCustomerRolesQuery } from "../../../../../generated/graphql";
import { AsyncSelect } from "../../async-select/AsyncSelect";
import { AsyncSelectData } from "../../async-select/AsyncSelectData";
import { useTranslation } from "react-i18next";

interface FormikFormSelectProps {
  fieldName: string;
  labelName: string;
  intialId?: number;
  formik;
  type?: string;
}

const FormikCustomerRoleFormSelect = (props: FormikFormSelectProps) => {
  const { data: CustomerRoles, loading: loadingCustomerRoles } =
    useGetCustomerRolesQuery();

  const formikSetFieldValue = props.formik.setFieldValue;
console.log("customer roles", CustomerRoles?.customerRoles.at(0)?.description)
    const { t } = useTranslation();

  const [initialCustomerRole, setInitialCustomerRole] =
    useState<AsyncSelectData>();

  useEffect(() => {
    if (CustomerRoles?.customerRoles) {
      let customerRole = CustomerRoles?.customerRoles?.find(
        (i) => i.id === props.intialId
      );
      if (customerRole) {
        let customerRoleSelectData = new AsyncSelectData({
          title: customerRole?.description ?? "",
          value: customerRole?.id,
        });
        setInitialCustomerRole(customerRoleSelectData);
        setTimeout(() => {
          formikSetFieldValue(props.fieldName, customerRole?.id ?? 0);
        }, 200);
      } else {
        setInitialCustomerRole(undefined);
        formikSetFieldValue(props.fieldName, 0);
      }
    }
  }, [
    setInitialCustomerRole,
    CustomerRoles,
    formikSetFieldValue,
    props.fieldName,
    props.intialId,
  ]);

  const selectedCustomerRoleChanged = (data: AsyncSelectData) => {
    formikSetFieldValue(props.fieldName, data.value);
  };


  return (
    <>
      <div className={`mt-3`}>
        <AsyncSelect
          label={t("modules.formik.role")}
          onItemSelected={selectedCustomerRoleChanged}
          onOpen={() => { console.log('') }}
          defaultSelectOption={initialCustomerRole}
          data={CustomerRoles?.customerRoles?.
             filter((i) => !i.isInternal)
            .map(
            (i) =>
              new AsyncSelectData({
                title: i.description ?? '',
                value: i.id,
              })
          )}
          loadingData={loadingCustomerRoles}
          hasError={Boolean(
            props.formik.errors[props.fieldName] &&
            props.formik.touched[props.fieldName]
          )}
          className={`w-full`}
        />
      </div>
    </>
  );
};

export { FormikCustomerRoleFormSelect };

