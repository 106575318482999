import { Env, EnvironmentConfiguration } from "./types";

export const OAuthScopes = 'global openid offline_access';


export type EnvironmentsType = { [key in Env]: EnvironmentConfiguration };

export const Environments: EnvironmentsType = {
    [Env.PROD]: {
      AuthenticatorURL: 'https://auth.hconnect.digital',
      BaseURL: 'https://api.hconnect.heidelbergcement.com',
      RedirectUri: 'https://karibu-admin.heidelbergmaterials.com/auth',
      ClientID: 'AEMMA',
      Identifier: Env.PROD,
      LegalUrl: 'https://legal.hconnect.digital',
      Stage: 'prod',
      OAuthScopes: OAuthScopes,
    },
    [Env.UAT]: {
      AuthenticatorURL: 'https://auth-uat.hconnect.digital',
      BaseURL: 'https://api-uat.hconnect.heidelbergcement.com',
      RedirectUri: 'https://karibu-admin-uat.heidelbergmaterials.com/auth',
      ClientID: 'AEMMA',
      Identifier: Env.UAT,
      LegalUrl: 'https://legal-uat.hconnect.digital',
      Stage: 'uat',
      OAuthScopes: OAuthScopes,
    },
    [Env.QA]: {
      AuthenticatorURL: 'https://auth-qa.hconnect.digital',
      BaseURL: 'https://api-qa.hconnect.heidelbergcement.com',
      RedirectUri: 'https://karibu-admin-qa.heidelbergmaterials.com/auth',
      ClientID: 'AEMMA',
      Identifier: Env.QA,
      LegalUrl: 'https://legal-qa.hconnect.digital',
      Stage: 'qa',
      OAuthScopes: OAuthScopes,
    },
    [Env.DEV]: {
      AuthenticatorURL: 'https://auth-qa.hconnect.digital',
      BaseURL: 'https://api-qa.hconnect.heidelbergcement.com',
      RedirectUri: 'https://karibu-admin-dev.heidelbergmaterials.com/auth',
      ClientID: 'AEMMA',
      Identifier: Env.DEV,
      LegalUrl: 'https://legal-dev.hconnect.digital',
      Stage: 'dev',
      OAuthScopes: OAuthScopes,
    },
    [Env.LOCAL]: {
        AuthenticatorURL: 'https://auth-qa.hconnect.digital',
        BaseURL: 'https://api-qa.hconnect.heidelbergcement.com',
        RedirectUri: 'http://localhost:3000/auth',
        ClientID: 'AEMMA',
        Identifier: Env.LOCAL,
        LegalUrl: 'https://legal-dev.hconnect.digital',
        Stage: 'dev',
        OAuthScopes: OAuthScopes,
      },
  };