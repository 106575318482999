import { LoadingButton } from "@mui/lab";
// import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import AuthContext from "../../contexts/auth-context";
import { useTranslation } from "react-i18next";

export const ApplicationError = () => {
  // const userService = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (userService?.authContextData?.currentUser) navigate("/landing");
  // }, [userService?.authContextData?.currentUser, navigate]);

  return (
    <div className={`fillPage gradient `}>
      <div className={`centralize`} style={{ width: "50%" }}>
        <h1 className="text-white text-3xl text-center mb-4">
          {t("messages.not-allowed")}
        </h1>

        <div className="w-full flex justify-center">
          <LoadingButton
            onClick={() => {
              navigate("/landing");
            }}
            variant="contained"
          >
            {t("messages.retry")}
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};
