import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {} from "react-router";
import { useLocation } from "react-router-dom";
import { AuthCodeFlow } from "./AuthCodeFlow";
import { getAuthClaimsEntity } from "src/app/utils";
import AuthContext from "src/app/contexts/auth-context";
import Loader from "src/app/pages/common/loader/loader";

const AuthCallback: React.FC = () => {
  const [authCode, setAuthCode] = useState<string | null>(null);
  const location = useLocation();
 
  const authCodeExchanged = useRef<boolean>(false); // Create a ref to track if the authorization code has been exchanged
  const authContext = useContext(AuthContext);




  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code") ?? "";
  const state = queryParams.get("state") ?? "";
  const error = queryParams.get("error") ?? "";

  const getAuthorizationClaims = useCallback(async () => {
    const authFlow = new AuthCodeFlow();

    try {
      const response = await authFlow.exchangeAuthorizationCode(
        code,
        state,
        error
      );
      const decodedToken = getAuthClaimsEntity(response.access_token);
      localStorage.setItem("token", response.access_token);
      localStorage.setItem("refresh_token", response.refresh_token);
      localStorage.setItem("id_token", response.id_token);

      authContext.getLoggedInUser?.(decodedToken?.email as string);

    } catch (error) {
      console.log(error);
    }
  }, [code, error, state, authContext]);

  useEffect(() => {
    if (authCodeExchanged.current) {
      // If we've already processed the auth code, don't do it again.
      return;
    }
    // Parse the query string to get the code

    if (code) {
      setAuthCode(code);
      console.log("Authcode captured");
      getAuthorizationClaims();
      authCodeExchanged.current = true;

      // Do something with the auth code, like calling the backend to exchange it for an access token
    }
  }, [location, code, getAuthorizationClaims]);

  

  return (
    <div>
      {/* {authCode ? (
        <div>
          <h1>Authorization Code:</h1>
          <code>{authCode}</code>
        </div>
      ) : (
        <p>Retrieving authorization code...</p>
      )} */}
      <Loader loaderSize={20}/>
    </div>
  );
};

export default AuthCallback;
