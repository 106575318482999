import { IconButton } from "@mui/material";
import Label from "../label/Label";
import OperationType from "../types/operation-type-enum";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const SideBoxHeader = (props: {
  operationType: OperationType | undefined;
  itemName: string;
  editScreenTitle?:string;
  customEditHeading?:JSX.Element;
  onClose?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="card__right__actions md:min-w-fit flex justify-between items-center border-b border-gray-900 mx-4 sticky -top-1 bg-white p-2 z-10">
        {props.operationType === OperationType.read && (
          <div className="save m-2">
            <Label
              beDeep={true}
              text={`${t("modules.form.view")} ${props.itemName}`}
            />
          </div>
        )}
        {props.operationType === OperationType.create && (
          <div className="save m-2">
            <Label
              beDeep={true}
              text={`${t("modules.form.new")} ${props.itemName}`}
            />
          </div>
        )}
        {props.operationType === OperationType.update && (
          <div className="save m-2">
            <Label
              beDeep={true}
              text={props.editScreenTitle??`${t("modules.form.update")} ${props.itemName}`}
            />
            {props.customEditHeading}
          </div>
        )}
        {!!onclose && (
          <div className="close m-2 flex items-center">
            <IconButton onClick={props.onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </div>
    </>
  );
};

export default SideBoxHeader;
