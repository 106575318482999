import { useTranslation } from "react-i18next";

export const MasterFooter = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="bg-white flex px-8 items-center">
        <div className="hconnect__log">
          <img
            className="w-24"
            src="/assets/images/hmaterials.png"
            alt="./"
          />
        </div>
        <p className="copyright text-center flex-grow text-gray-400">
          {t("MainFooter.copyright")}
        </p>
        <div className="hconnect flex p-4">
          <div className="powered text-sm text-[#B3B3B3]">
            {t("MainFooter.powered-by")}{" "}
            <span className="text-xl font-bold tracking-wide text-[#004D2C]">
              {t("MainFooter.division")}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
