import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Button,
  Card,
  CardMedia,
} from "@mui/material";
import { FormikCompanyFormSelect } from "./custom-selects/FormikCompanyFormSelect";
// import { FormikLocationFormSelect } from "./custom-selects/FormikLocationFormSelect";
import { FormikCountryFormSelect } from "./custom-selects/FormikCountryFormSelect";
import { FormFieldOption } from "./FormikFormFieldBuilder";

import {
  LocalizationProvider,
  MobileDateTimePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import { useState } from "react";
import {
  convertfromISO8601,
  convertToISO8601,
  formattedPTTime,
} from "../../../helpers/misc";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { styled } from "@mui/material/styles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FormikProductMeasurementFormSelect } from "./custom-selects/FormikProductMeasurementFormSelect";
import { FormikUnitOfMeasureFormSelect } from "./custom-selects/FormikUnitOfMeasureFormSelect";
import Delete from "@mui/icons-material/Delete";
import { FormikLocationFormSelect } from "./custom-selects/FormikLocationFormSelect";
import { FormikCustomerSegementFormSelect } from "./custom-selects/FormikCustomerSegementFormSelect";
import { FormikProductFormSelect } from "./custom-selects/FormikProductFormSelect";
import { FormikUserFormSelect } from "./custom-selects/FormikUserFormSelect";
import VehicleLookupSelect from "../lookup-select/vehicle-lookup-select/VehicleLookupSelect";
import { DescriptionValue, Vehicle } from "src/generated/graphql";
import { FormikCustomerRoleFormSelect } from "./custom-selects/FormikCustomerRoleFormSelect";
import { FormikCustomerFormSelect } from "./custom-selects/FormikCustomerFormSelect";
import DescriptionLookupSelect from "../lookup-select/description-lookup-select/DescriptionLookupSelect";
import LocationTypeLookupSelect from "../lookup-select/location-type-lookup-select/LocationTypeLookupSelect";
export interface FormikFormFieldProps {
  formik;
  editScreenFormikObject?;
  fieldOption: FormFieldOption;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FormikFormField = ({
  formik,
  fieldOption: {
    labelName,
    fieldName,
    defaultValue,
    type = "text",
    radioValues = [],
    placeHolder,
    intialId,
    textRows = 1,
    // ignoreSelectedLocation = false,
    // selectedLocationId,
    disabled = false,
    idFilterList,
    format,
    // selectItemChanged,
  },
}: FormikFormFieldProps) => {
  // console.log("Error field: ", formik.values[fieldName])
  const [dateTimeData, setDateTimeData] = useState<moment.Moment>(() => {

    if (type === "dateTime")
      return moment(convertfromISO8601(formik.values[fieldName]))
    else
      return moment(new Date());
  }
  );
  const [timeData, setTimeData] = useState<moment.Moment>(() => {
    if (type === "time") {
      if (format === "ISO8601")
        return moment(convertfromISO8601(formik.values[fieldName]));
      else
        return formattedPTTime(
          formik.values[fieldName],
          "momentObject"
        ) as Moment;
    }
    return moment(new Date());
  });

  const [image, setImage] = useState<string | undefined>();

  const booleanCheckboxChanged = (evt) => {
    formik.setFieldValue(fieldName, evt.target.checked);
  };

  const radioValueChanged = (evt) => {
    let value = evt.target.value;
    let booleanValue =
      value === "true" ? true : value === "false" ? false : undefined;
    formik.setFieldValue(fieldName, booleanValue ?? value);
  };

  // const booleanCheckboxChanged = (evt) => {
  //   formik.setFieldValue(fieldName, evt.target.checked);
  // };

  const getCustomLabel = () => {
    // if(type === "date")return "";

    return labelName;
  };

  const getCustomValue = () => {
    if (type === "licensePlateNo") {
      return (formik.values[fieldName] = String(formik.values[fieldName])
        .replace(/[^A-Za-z0-9-/]/, "")
        .toUpperCase()
        .slice(0, 15)
        .trim());
    }
    if (fieldName === "firstname" || fieldName === "lastname") {
      return (formik.values[fieldName] = String(formik.values[fieldName])
        .slice(0, 79)
        .trim());
    }
    if (fieldName === "licenseNo") {
      return (formik.values[fieldName] = String(formik.values[fieldName])
        .slice(0, 32)
        .trim());
    }

    console.log(formik)

    return formik.values[fieldName];
  };

  console.log({ formik });

  const fileChanged = ({ target: { validity, files } }) => {
    if (validity.valid) {
      var tempFile = files[0];
      console.log({ tempFile });
      formik.setFieldValue(fieldName, tempFile);
      setImage(URL.createObjectURL(tempFile));
    }
  };

  const deleteFile = () => {
    formik.setFieldValue(fieldName, null);
    console.log("Image deleted", formik.values[fieldName]);
  };

  const richTextChanged = (content, delta, source, editor) => {
    formik.setFieldValue(fieldName, editor.getHTML());
  };

  const vehicleSelected = (item: Vehicle) => {
    formik.setFieldValue(fieldName, item?.id);
  };

  const descriptionSelected = (item: DescriptionValue) => {
    formik.setFieldValue(fieldName, item?.id);
  };

  const locationTypeSelected = (item: any) => {
    formik.setFieldValue(fieldName, item);
  };

  return (
    <div className={`mt-8`}>
      {(type === "text" ||
        type === "number" ||
        type === "licensePlateNo" ||
        type === "date") && (
          <TextField
            disabled={disabled}
            name={fieldName}
            placeholder={placeHolder || labelName}
            multiline={type === "text" ? true : false}
            rows={type === "text" ? textRows : 1}
            maxRows={15}
            onWheel={(event) => {
              (event?.target as any)?.blur();
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && textRows > 1) {
                event.preventDefault();
                formik.setFieldValue(fieldName, formik.values[fieldName] + "\n");
              }
            }}
            InputLabelProps={{ shrink: true }}
            value={getCustomValue()}
            onChange={formik.handleChange}
            label={getCustomLabel()}
            type={type}
            className={`w-full `}
          />
        )}

      {type === "dateTime" && (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileDateTimePicker
            label={labelName}
            className={"w-full"}
            value={dateTimeData}
            onChange={(date) => {
              setDateTimeData(date ?? moment(new Date()));
              formik.setFieldValue(fieldName, date?.toDate);
            }}
          />
        </LocalizationProvider>
      )}

      {type === "time" && (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileTimePicker
            label={labelName}
            className={"w-full"}
            value={timeData}
            onChange={(date) => {
              setTimeData(date ?? moment(new Date()));
              formik.setFieldValue(fieldName, convertToISO8601(date?.toDate()));
            }}
          />
        </LocalizationProvider>
      )}

      {type === "radio" && (
        <FormControl>
          <FormLabel>{labelName}</FormLabel>
          <RadioGroup
            row
            onChange={radioValueChanged}
            name={labelName}
            value={formik.values[fieldName]}
          >
            {radioValues.map((radValue) => (
              <FormControlLabel
                key={radValue.label}
                value={radValue.value}
                control={<Radio />}
                label={radValue.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}

      {type === "countryFormSelect" && (
        <FormikCountryFormSelect
          idFilterList={idFilterList}
          formik={formik}
          disabled={disabled}
          intialId={intialId}
          fieldName={fieldName}
          labelName={labelName}
        />
      )}
      {type === "boolean" && (
        <div className="flex items-center">
          <Checkbox
            disabled={disabled}
            onChange={booleanCheckboxChanged}
            checked={!disabled ? formik.values[fieldName] : false}
          />
          <p>{labelName}</p>
        </div>
      )}

      {type === "locationFormSelect" && (
        <FormikLocationFormSelect
          showLabel={true}
          formik={formik}
          intialId={intialId}
          fieldName={fieldName}
          labelName={labelName}
        />
      )}

      {type === "companyFormSelect" && (
        <FormikCompanyFormSelect
          formik={formik}
          intialId={intialId}
          showLabel={true}
          fieldName={fieldName}
          labelName={labelName}
        />
      )}
      {type === "customerSegmentFormSelect" && (
        <FormikCustomerSegementFormSelect
          formik={formik}
          intialId={intialId}
          showLabel={true}
          fieldName={fieldName}
          labelName={labelName}
        />
      )}
      {type === "customerFormSelect" && (
        <FormikCustomerFormSelect
          formik={formik}
          intialId={intialId}
          showLabel={true}
          fieldName={fieldName}
          labelName={labelName}
        />
      )}
      {type === "productFormSelect" && (
        <FormikProductFormSelect
          formik={formik}
          intialId={intialId}
          showLabel={true}
          fieldName={fieldName}
          labelName={labelName}
        />
      )}

      {type === "customerRoleFormSelect" && (
        <FormikCustomerRoleFormSelect
          formik={formik}
          intialId={intialId}
          fieldName={fieldName}
          labelName={labelName}
        />
      )}


      {type === "productMeasurementFormSelect" && (
        <FormikProductMeasurementFormSelect
          formik={formik}
          intialId={intialId}
          fieldName={fieldName}
          labelName={labelName}
        />
      )}

      {type === "unitOfMeasureFormSelect" && (
        <FormikUnitOfMeasureFormSelect
          formik={formik}
          intialId={intialId}
          fieldName={fieldName}
          labelName={labelName}
        />
      )}
      {type === "userFormSelect" && (
        <FormikUserFormSelect
          formik={formik}
          disabled={disabled}
          intialId={intialId}
          fieldName={fieldName}
          defaultEmail={defaultValue}
          labelName={labelName}
        />
      )}

      {type === "file" && (
        <div className="flex items-center gap-4">
          {/*{<p className="text-[#00274D] font-medium">{labelName}</p>}*/}
          <Card sx={{ minHeight: 101, height: 101, width: 101 }}>
            <CardMedia
              id="imagePreview"
              component="img"
              image={image === undefined ? formik.values[fieldName] : image}
              alt="Upload file"
            />
          </Card>
          <Button
            component="label"
            role={undefined}
            tabIndex={-1}
            startIcon={<CameraAltIcon />}
          >
            <p className="text-[#00274D] font-medium">Upload image from file</p>
            <VisuallyHiddenInput
              type="file"
              accept="image/*"
              onChange={fileChanged}
            />
          </Button>
          <Button
            component="label"
            role={undefined}
            tabIndex={-1}
            startIcon={<Delete />}
            onClick={deleteFile}
          >
            <p className="text-[#00274D] font-medium">Delete image</p>
          </Button>
        </div>
      )}

      {type === "richText" && (
        <div className="flex flex-col">
          <p className="text-[#00274D] font-medium">{labelName}</p>
          <ReactQuill
            theme="snow"
            value={formik.values[fieldName]}
            onChange={richTextChanged}
            style={{
              height: "400px",
            }}
          />
        </div>
      )}

      {type === "vehicleFormSelect" && (
        <div>
          <p className="text-[#00274D] font-semibold text-2xl">{labelName}</p>
          <br />
          <VehicleLookupSelect
            onVehicleSelected={vehicleSelected}
          />
        </div>
      )}

      {type === "descriptionFormSelect" && (
        <DescriptionLookupSelect
          onDescriptionSelected={descriptionSelected}
        />
      )}

      {type === "locationTypeSelect" &&
        <LocationTypeLookupSelect
          onLocationTypeSelected={locationTypeSelected}
        />}

      {formik.errors[fieldName] && formik.touched[fieldName] ? (
        <div className={"errorMsg"}>{formik.errors[fieldName]}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FormikFormField;
