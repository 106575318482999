import { Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

import {
  convertfromISO8601,
  convertFromPascal,
  daysObjects,
  formattedPTTime,
  toTitleCase,
} from "../../../helpers/misc";

type ValueManipulator =
  | "defaultTimeString"
  | "IsoToTimeString"
  | "boolean"
  | "locationNameFromObject"
  | "weekDay"
  | "locationType"
  | "NoManipulation"
  | "imageUrl";
type KeyManipulator = "Capitalize" | "TitleCase" | "Unbold" | "Raw";

interface ObjectAsFiledsProp<T> {
  obj: T;
  fieldConfig?: { [Property in keyof T]?: FieldConfigValue };
  excludeFields?: string[];
}

export interface FieldConfigValue {
  customLabel?: string;
  valueManipulator?: ValueManipulator;
  keyManipulator?: KeyManipulator[];
  keepInOrder?: boolean;
}

export interface TruckExtension {
  "country.name": string;
  "supplier.supplierName": string;
}
export interface ProductLocationExtension {
  "product.code": string;
  "product.description": string;
  "location.name": string;
  "location.code": string;
  "location.company.name": string;
  "product.productType.description": string;
  "product.eamPartCode": string;
  "product.sunItemCode": string;
}

export interface LocationExtension {
  "cust.service": string;
  "company.name": string;
  "parentLocation.name": string;
}

export interface ProductExtension {
  "company.code": string;
  "company.name": string;
  "productMeasurement.description": string;
  "unitOfMeasure.description": string;
}
export interface CompanyExtension {
  "country.name": string;
  "defaultSupplierNonPO.supplierCode": string;
  "defaultSupplierNonPO.supplierName": string;
}

function ObjectAsFields<T>({
  obj,
  fieldConfig,
  excludeFields,
}: ObjectAsFiledsProp<T>) {
  const fieldConfigKeys = Object.keys(fieldConfig ?? {});
  const { t } = useTranslation();
  const keyValueRender = (key: string, value, config?: FieldConfigValue) => {
    if (excludeFields?.includes(key)) return "";

    if (
      key.includes("typename") ||
      key.includes("translationKey") ||
      key.toLowerCase().includes("id")
    )
      return "";
    return (
      <div key={key} className="view-item m-2">
        {!!(
          typeof value === "boolean" || config?.valueManipulator == "boolean"
        ) && (
          <Checkbox
            sx={{
              "&": {
                paddingLeft: "0px",
                paddingRight: "5px",
                display: "inline",
              },
            }}
            checked={value}
            disabled
          />
        )}

        <div
          className={`view-item__heading inline ${
            config?.keyManipulator?.includes("Unbold") ? "" : "font-bold"
          } text-[13px]`}
        >
          {key === "truckPark"
            ? t("modules.upsert.location.form.truck-park")
            : formatKey(key, config?.keyManipulator as KeyManipulator[])}
        </div>

        {!!(
          (typeof value === "string" || typeof value === "number") &&
          config?.valueManipulator != "boolean"
        ) &&
          config?.valueManipulator != "imageUrl" && (
            <div className="view-item__text">
              {formatValue(value, config?.valueManipulator)}
            </div>
          )}

        {!!(
          typeof value === "string" && config?.valueManipulator === "imageUrl"
        ) && (
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia component="img" height="194" image={value} alt={value} />
          </Card>
        )}

        {!!(value && typeof value === "object") && (
          <div className="view-item__text">
            {value.__typename == "Location" && <p>{value?.name}</p>}
            {value.__typename == "UserLocation" && (
              <p>{value?.location?.name}</p>
            )}
          </div>
        )}

        {!!(value?.map && config?.valueManipulator != "boolean") &&
          value?.map((i) => {
            if (i.__typename === "TracingEmail") {
              return (
                <p key={i?.id} className="">
                  {i?.email ?? ""}
                </p>
              );
            } else if (typeof i === "object") {
              if (i.__typename === "UserGroup") {
                return <li key={i?.id}>{i?.description}</li>;
              }
              if (i.__typename === "Location") {
                return <li key={i?.id}>{i?.name}</li>;
              }
              if (i.__typename === "UserLocation") {
                return <li key={i?.id}>{i?.location?.name}</li>;
              }
              return (
                <p
                  key={i?.name ?? i?.description ?? i?.location?.name}
                  className=""
                >
                  {convertFromPascal(i?.name) ??
                    convertFromPascal(i?.description) ??
                    i?.location?.name ??
                    ""}
                </p>
              );
            }
            return i;
          })}
      </div>
    );
  };

  const formatValue = (
    value,
    manipulator: ValueManipulator = "NoManipulation"
  ) => {
    if (manipulator === "IsoToTimeString") {
      return convertfromISO8601(value).toLocaleTimeString();
    } else if (manipulator === "defaultTimeString") {
      return formattedPTTime(value);
    } else if (manipulator === "locationNameFromObject") {
      return value?.name;
    } else if (manipulator === "weekDay") {
      return daysObjects.find((i) => i.id == value)?.name;
    } else if (manipulator === "locationType") {
      return value.toLowerCase() === "p"
        ? t("modules.upsert.location.form.plant")
        : value.toLowerCase() === "a"
        ? t("modules.upsert.location.form.aggregate")
        : t("modules.upsert.location.form.depot");
    }
    console.log({ value });
    /*     const rawHTML = value.replace(/\n/g, "<br />");
    return <p dangerouslySetInnerHTML={{ __html: rawHTML }} />; */
    return value;
  };

  const formatKey = (key: string, manipulator: KeyManipulator[]) => {
    if (manipulator?.includes("Capitalize")) {
      return key.toUpperCase().trim();
    }
    if (key === "licenseExpDate") {
      return t("modules.ticket-details.license-expiry-date").trim();
    }
    if (manipulator?.includes("Raw")) {
      return key.trim();
    }
    return toTitleCase(key.replace(".", " ")).trim();
  };

  return (
    <>
      {fieldConfigKeys?.map((i) => {
        let config = fieldConfig?.[i];
        if (config.keepInOrder !== false)
          return keyValueRender(config?.customLabel ?? i, obj[i ?? ""], config);
      })}
      {obj &&
        Object.keys(obj as Object)?.map((i) => {
          if (!fieldConfigKeys.includes(i)) return keyValueRender(i, obj[i]);
          else if (fieldConfigKeys.includes(i)) {
            let config = fieldConfig?.[i];
            if (config.keepInOrder === false)
              return keyValueRender(
                config?.customLabel ?? i,
                obj[i ?? ""],
                config
              );
          }
          return "";
        })}
    </>
  );
}

export default ObjectAsFields;
