import { BrowserRouter } from "react-router-dom";
import PermissionProvider from "../providers/permissions/permission-providers";
import { useState, useMemo } from "react";
import UserPermissionService from "../providers/permissions/user-permissions-service";
import { AllRoutes } from "./AllRoutes";
import { AuthContextProvider } from "../providers/auth-context/auth-context-provider";

const AppRoutes = () => {
  const ps = UserPermissionService();

  const [permissions, setPermissions] = useState<string[] | undefined>(
    ps.permissions
  );

 
  useMemo(() => {
    setPermissions(ps.permissions);
  }, [ps.permissions, setPermissions]);

  return (
    <BrowserRouter>
      <AuthContextProvider>
        <PermissionProvider permissions={permissions}>
          <AllRoutes></AllRoutes>
        </PermissionProvider>
      </AuthContextProvider>
    </BrowserRouter>
  );
};

export { AppRoutes };
