import { SilentRequest } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { loginRequest } from "../../config/auth-config";
import UnauthorizedAd from "./UnauthorizedAd";

interface IProps {
  children: any;
  acceptedAzureGroupIds: string[];
}

const MsalGroup = (props: IProps) => {
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0]);
  const { acceptedAzureGroupIds, children } = props;
  const [authorized, setAuthorized] = useState<boolean>(true);
  const [userAdGroup, setUserAdGroup] = useState<string[]>([]);

  useEffect(() => {
    const fetchAdGroup = async () => {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      } as SilentRequest);
      if (
        !response ||
        !response.idTokenClaims ||
        !(response.idTokenClaims as any).groups
      ) {
        localStorage.clear();
      } else {
        setUserAdGroup((response.idTokenClaims as any).groups);
        localStorage.setItem(
          "userADGroups",
          JSON.stringify((response.idTokenClaims as any).groups)
        );
        const found = acceptedAzureGroupIds.some((acceptedAzGroup) =>
          (response.idTokenClaims as any)?.groups.includes(acceptedAzGroup)
        );
        setAuthorized(found);
      }
    };
    fetchAdGroup().catch(() => {
      console.log("");
    });
  }, [instance, acceptedAzureGroupIds, account]);

  return (
    <>
      {(authorized && <> {children}</>) || (
        <UnauthorizedAd invalidAdGroup={userAdGroup} />
      )}
    </>
  );
};

export default MsalGroup;
