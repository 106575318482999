import { useEffect, useState } from "react";
import { useGetProductMeasurementsQuery } from "../../../../../generated/graphql";
import { AsyncSelect } from "../../async-select/AsyncSelect";
import { AsyncSelectData } from "../../async-select/AsyncSelectData";
import { useTranslation } from "react-i18next";

interface FormikFormSelectProps {
  fieldName: string;
  labelName: string;
  intialId?: number;
  formik;
  type?: string;
}

// FormikProps<FormikConfig<FormikValues>>
const FormikProductMeasurementFormSelect = (props: FormikFormSelectProps) => {
  const { data: ProductMeasurements, loading: loadingProductMeasurements } =
    useGetProductMeasurementsQuery();

  const formikSetFieldValue = props.formik.setFieldValue;

  const [initialProductMeasurement, setInitialProductMeasurement] =
    useState<AsyncSelectData>();

  const { t } = useTranslation();

  useEffect(() => {
    if (ProductMeasurements?.productMeasurements) {
      let productMeasurement = ProductMeasurements?.productMeasurements?.find(
        (i) => i.id === props.intialId
      );
      if (productMeasurement) {
        let productMeasurementSelectData = new AsyncSelectData({
          title: productMeasurement?.description,
          value: productMeasurement?.id,
        });
        setInitialProductMeasurement(productMeasurementSelectData);
        setTimeout(()=>{
          formikSetFieldValue(props.fieldName, productMeasurement?.id??0);
        },200);
      } else {
        setInitialProductMeasurement(undefined);
        formikSetFieldValue(props.fieldName, 0);
      }
    }
  }, [
    setInitialProductMeasurement,
    ProductMeasurements,
    formikSetFieldValue,
    props.fieldName,
    props.intialId,
  ]);

  const selectedProductMeasurementChanged = (data: AsyncSelectData) => {
    formikSetFieldValue(props.fieldName, data.value);
  };


  return (
    <>
      <div className={`mt-3`}>
        <AsyncSelect
          label={t("modules.formik.product-measurement-label")}
          onItemSelected={selectedProductMeasurementChanged}
          onOpen={() => {console.log('')}}
          defaultSelectOption={initialProductMeasurement}
          data={ProductMeasurements?.productMeasurements?.map(
            (i) =>
              new AsyncSelectData({
                title: i.description,
                value: i.id,
              })
          )}
          loadingData={loadingProductMeasurements}
          hasError={Boolean(
            props.formik.errors[props.fieldName] &&
              props.formik.touched[props.fieldName]
          )}
          className={`w-full`}
        />
        {/* {props.formik.errors[props.fieldName] &&
          props.formik.touched[props.fieldName] ? (
            <div className="errorMsg">
              {props.formik.errors[props.fieldName]}
            </div>
          ) : (
            ""
          )} */}
      </div>
    </>
  );
};

export { FormikProductMeasurementFormSelect };
