import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import Notifier, {
  emptyNotification,
  NotificationMessage,
  NotificationType,
} from "../../../common/notifier/notifier";
import style from "./masterHeader.module.scss";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography";
import Notification from "./components/Notification";
import { ReactComponent as Karibu } from "../images/Karibu.svg";
import DropDown from "src/app/pages/common/drop-down/drop-down";
import { DropdownItemSelectedParams } from "src/app/pages/common/drop-down/DropdownItemSelectedParams";
import {
  useGetLanguagesQuery,
  useGetPaymentIdByPaymentNumberLazyQuery,
  useGetUserNotificationsLazyQuery,
  useMarkNotificationAsReadMutation,
  useUpsertUserDefaultLanguagemutationMutation,
} from "src/generated/graphql";
import AuthContext from "src/app/contexts/auth-context";
import Loader from "src/app/pages/common/loader/loader";
import { LocationCityOutlined } from "@mui/icons-material";
import { MessagePayload, onMessage } from "firebase/messaging";
import { messaging } from "src/app/firebase/firebase";
import { FCMNotificationTypeEnum } from "src/app/pages/common/types/notification-type-enum";
import { NotificationCardProps } from "./components/NotificationCard";

interface FCMNotificationData {
  notification_id: string;
  component_name: string;
  component_id: string;
  notification_type_id: string;
}

export const MasterHeader = () => {
  const { t, i18n } = useTranslation();
  const userService = useContext(AuthContext);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] =
    useState<NotificationMessage>();

  const [getPaymentId, { data: paymentData }] =
    useGetPaymentIdByPaymentNumberLazyQuery();
  const [getNotifications, { data: notificationData }] =
    useGetUserNotificationsLazyQuery();
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation();
  const [fcmPayloadData, setFCMPayloadData] = useState<MessagePayload>();
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<
    string | undefined
  >(userService?.authContextData?.currentUser?.language?.code);

  useEffect(() => {
    i18n.changeLanguage(selectedDropdownValue?.toLowerCase());
  }, [userService, i18n, selectedDropdownValue]);

  const gotoDashboard = () => {
    navigate("/landing");
  };

  const onHideNotification = () => {
    setNotificationMessage(emptyNotification(notificationMessage));
    getNotifications();
  };
  const [notifications, setNotifications] = useState<NotificationCardProps[]>(
    []
  );
  const [isFcmNotification, setIsFcmNotification] = useState<boolean>();

  useEffect(() => {
    if (notificationData?.userNotifications?.nodes?.length) {
      let noticeData = notificationData?.userNotifications?.nodes?.map(
        (val) => {
          return {
            title: val?.notification?.title,
            message: val?.notification?.body,
            date: val?.createdAt,
            onClick: () => {
              setIsFcmNotification(false);
              if (
                val?.notification?.notificationTypeId ===
                Number(FCMNotificationTypeEnum.ManualPaymentRequest)
              ) {
                getPaymentId({
                  variables: {
                    paymentNumber: String(val?.notification?.componentId),
                  },
                });
              }
              if (
                val?.notification?.notificationTypeId ===
                Number(FCMNotificationTypeEnum.Quotation)
              ) {
                navigate("orders-details/" + val?.notification?.componentId);
              }
              markNotificationAsRead({
                variables: {
                  notificationId: Number(val?.notification?.id),
                },
              });
              getNotifications();
            },
          };
        }
      ) as NotificationCardProps[];
      setNotifications(noticeData);
    }
  }, [
    notificationData?.userNotifications?.nodes,
    markNotificationAsRead,
    getPaymentId,
    getNotifications,
    navigate,
  ]);
  const { data: languagesData, loading: loadingDefaultLanguage } =
    useGetLanguagesQuery();

  const [upsertUserDefaultLanguagemutationMutation] =
    useUpsertUserDefaultLanguagemutationMutation();

  const onDropdownItemSelected = (
    itemSelectedParams: DropdownItemSelectedParams
  ) => {
    setSelectedDropdownValue(itemSelectedParams.selectedItem);
    let selectedLang = languagesData?.languages?.find(
      (val) => val.code === itemSelectedParams.selectedItem.toLowerCase()
    );

    if (selectedLang && selectedLang.id !== undefined) {
      upsertUserDefaultLanguagemutationMutation({
        variables: {
          langId: { languageId: selectedLang.id },
        },
      });
    }
  };

  const FilterItems = useMemo<string[]>(() => {
    if (languagesData?.languages) {
      return languagesData?.languages?.map((value) =>
        value?.code?.toUpperCase()
      );
    }
    return [];
  }, [languagesData?.languages]);

  onMessage(messaging, (payload) => {
    setIsFcmNotification(true);
    if (
      payload?.data?.notification_type_id ===
      FCMNotificationTypeEnum.ManualPaymentRequest
    ) {
      getPaymentId({
        variables: {
          paymentNumber: payload?.data?.component_id,
        },
      });
    }
    setFCMPayloadData(payload);
  });

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  useEffect(() => {
    if (paymentData?.payments?.nodes?.at(0)?.id && isFcmNotification) {
      setNotificationMessage({
        message: String(fcmPayloadData?.notification?.title),
        type: NotificationType.info,
      });
    }
    if (
      paymentData?.payments?.nodes?.at(0)?.id &&
      isFcmNotification === false
    ) {
      navigate(`manual-payments/${paymentData?.payments?.nodes?.at(0)?.id}`);
      setIsFcmNotification(undefined);
      getNotifications();
    }
  }, [
    paymentData?.payments?.nodes,
    fcmPayloadData?.notification?.title,
    navigate,
    getNotifications,
    isFcmNotification,
  ]);

  const handleNotificationPress = (data: FCMNotificationData) => {
    if (
      data.notification_type_id === FCMNotificationTypeEnum.ManualPaymentRequest
    ) {
      navigate(`manual-payments/${paymentData?.payments?.nodes?.at(0)?.id}`);
      markNotificationAsRead({
        variables: {
          notificationId: Number(data.notification_id),
        },
      });
      setIsFcmNotification(undefined);
    }
    if (data.notification_type_id === FCMNotificationTypeEnum.Quotation) {
      navigate("orders-details/" + data.component_id);
      markNotificationAsRead({
        variables: {
          notificationId: Number(data.notification_id),
        },
      });
      setIsFcmNotification(undefined);
    }
    getNotifications();
  };

  return (
    <>
      <div className={`bg-white flex px-8 items-center`}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            <div className="brand">
              <span onClick={gotoDashboard} style={{ cursor: "pointer" }}>
                <Karibu width={139.38} height={40.05} />
              </span>
            </div>
          </Typography>
        </Toolbar>
        <div className="masterHeader__middle ml-4 flex-1 min-w-0 z-0">
          <ul className={`${style.mainNavigation} ${style.ul} z-1`}>
            <li>
              <NavLink
                className={({ isActive }) => `${isActive ? style.active : ""}`}
                end
                to="/landing"
              >
                {t("MainHeader.overview")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => `${isActive ? style.active : ""}`}
                to="/orders"
              >
                {t("MainHeader.orders")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => `${isActive ? style.active : ""}`}
                to="/payments"
              >
                {t("MainHeader.payments")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => `${isActive ? style.active : ""}`}
                to="/configurations"
              >
                {t("MainHeader.configurations")}
              </NavLink>
            </li>

            <li>
              <NavLink
                className={({ isActive }) => `${isActive ? style.active : ""}`}
                to="/reports"
              >
                {t("MainHeader.reports")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => `${isActive ? style.active : ""}`}
                to="/content-management"
              >
                {t("MainHeader.content-management")}
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="relative mr-12 flex items-center justify-center">
          <LocationCityOutlined fontSize="large" />
          <h2 className=" ml-2 text-[13px] text-[#274867] ">
            {userService.authContextData?.currentUser?.location?.company.name}
          </h2>
        </div>
        <div className="relative mr-12 flex items-center">
          {loadingDefaultLanguage && <Loader />}
          {languagesData && (
            <DropDown
              className={""}
              onItemSelected={onDropdownItemSelected}
              searchList={FilterItems}
              size="lg"
              frontLogoType="language"
            />
          )}
        </div>
        <div className="relative">
          <button
            onClick={() => setIsOpen(!isOpen)}
            onBlur={() => {
              setTimeout(() => {
                setIsOpen(false);
              }, 400);
            }}
            disabled={!notificationData?.userNotifications?.nodes?.length}
            className="relative z-5 block border-2 rounded-full p-2"
          >
            <NotificationsOutlinedIcon sx={{ fontSize: 20 }} />
            {!!notificationData?.userNotifications?.nodes?.length && (
              <span className="absolute top-0 -right-2 inline-block px-2 py-1 text-xs font-bold leading-none text-white bg-yardBackgroundLight rounded-full">
                {notificationData?.userNotifications?.nodes?.length}
              </span>
            )}
          </button>

          {isOpen && (
            <div className="absolute right-0 w-[20vw] mt-2 bg-white rounded-lg border shadow-xl z-[12]">
              <Notification notificationData={notifications} />
            </div>
          )}
        </div>
        <button
          onClick={() => {
            navigate("profile");
          }}
          className="relative z-5 block ml-10 border-2 rounded-full p-2"
        >
          <PermIdentityIcon sx={{ fontSize: 20 }} />
        </button>
      </div>
      <Notifier
        notificationMessage={notificationMessage}
        onHideNotifier={onHideNotification}
        onClick={() => {
          handleNotificationPress(
            fcmPayloadData?.data as any as FCMNotificationData
          );
          onHideNotification();
        }}
      />
    </>
  );
};
