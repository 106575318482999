import { useContext, useEffect, useState } from "react";
import { useGetUsersByCompanyIdLazyQuery } from "../../../../../generated/graphql";
import { AsyncSelect } from "../../async-select/AsyncSelect";
import { AsyncSelectData } from "../../async-select/AsyncSelectData";
import { useTranslation } from "react-i18next";
import AuthContext from "src/app/contexts/auth-context";
import { TextField } from "@mui/material";

interface FormikFormSelectProps {
  fieldName: string;
  labelName?: string;
  disabled: boolean;
  intialId?: number;
  defaultEmail?: string;
  formik;
  type?: string;
  showLabel?: boolean;
}

const FormikUserFormSelect = (props: FormikFormSelectProps) => {
  const userService = useContext(AuthContext);

  console.log("Props", props);
  const { t } = useTranslation();

  const [getCustomerAccountUsers, { data, loading, error, fetchMore }] = useGetUsersByCompanyIdLazyQuery();
  const [searchTerm, setSearchTerm] = useState('');


  const formikSetFieldValue = props.formik.setFieldValue;
  const [initialUser, setInitialUser] = useState<AsyncSelectData>();


  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm) {
        getCustomerAccountUsers({
          variables: {
            companyId: Number(userService?.authContextData?.currentUser?.companyId),
            search: searchTerm
          }
        });
      }
    }, 300); // 300ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, getCustomerAccountUsers, userService?.authContextData?.currentUser?.companyId]);

  useEffect(() => {

    if (data?.usersByCompany?.nodes) {
      console.log(data?.usersByCompany);
      let users = data?.usersByCompany?.nodes?.find(
        (i) => i.id === props.intialId
      );
      if (users) {
        let userData = new AsyncSelectData({
          title: users?.fullName,
          value: users?.id,
        });
        setInitialUser(userData);
        setTimeout(() => {
          formikSetFieldValue(props?.fieldName, users?.id ?? 0);
        }, 200);
      } else {
        setInitialUser(undefined);
        formikSetFieldValue(props?.fieldName, 0);
      }
    }
  }, [
    setInitialUser,
    data,
    formikSetFieldValue,
    props?.fieldName,
    props?.intialId
  ]);

  const selectedUserChanged = (data: AsyncSelectData) => {
    formikSetFieldValue(props?.fieldName, data.value);
  };
  const handleInputChange = (inputValue: any) => {
    setSearchTerm(inputValue.target.value);
  };

  return (
    <>
      <div className={`mt-3`}>
        {!props.defaultEmail && <AsyncSelect
          label={props.showLabel ? t("modules.formik.bank-label") : ""}
          onItemSelected={selectedUserChanged}
          onOpen={() => { console.log('') }}
          defaultSelectOption={initialUser}
          disabled={props.disabled}
          key={props?.fieldName}
          inputValueChanged={handleInputChange}
          data={data?.usersByCompany?.nodes?.map(
            (i) =>
              new AsyncSelectData({
                title: `${i.fullName} - ${i.email}`,
                value: i.id,
              })
          )}
          loadingData={loading}
          hasError={Boolean(
            props.formik.errors?.[props?.fieldName] &&
            props.formik.touched?.[props?.fieldName]
          )}
          className={`w-full`}
        />}
        {
          !!props.defaultEmail && (
            <TextField
              value={props.defaultEmail}
              disabled={true}
              className="w-full"
            />
          )
        }
      </div>
    </>
  );
};

export { FormikUserFormSelect };
