import { useTranslation } from "react-i18next";
import NotificationCard, { NotificationCardProps } from "./NotificationCard";

function Notification(props: { notificationData: NotificationCardProps[] }) {
  const { t } = useTranslation();
  return (
    <div
      className=" h-[50%]"
      style={{ zIndex: 1000, display: "block", position: "relative", height:"300px" }}
    >
      <h2 className=" text-xl font-semibold p-4 ">
        {t("labels.unread-notifications")}
      </h2>
      <div className="w-full h-[100%] overflow-y-auto">
        {props.notificationData
          .sort((a, b) => (a.date < b.date ? 1 : -1))
          .map((value) => (
            <NotificationCard
              title={value.title}
              message={value.message}
              date={value.date}
              onClick={value.onClick}
            />
          ))}
      </div>
    </div>
  );
}

export default Notification;