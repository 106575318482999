import { useMsal } from "@azure/msal-react";
import VpnLockIcon from '@mui/icons-material/VpnLock';
import { LoadingButton } from "@mui/lab";

interface IProps {
  invalidAdGroup: string[]
}

const UnauthorizedAd = (props: IProps) => {
  const { instance } = useMsal();
  console.log(props)
  return (
    <>
      <div className={`fillPage gradient `}>
        <div className={`centralize text-center`} style={{ width: "50%" }}>
          <VpnLockIcon htmlColor="white" fontSize="large" className="mb-4" />
          <h1 className="text-white text-3xl text-center mb-4">
            You are not allowed to use this Application, please contact your
            system administrator
          </h1>
          <div className="w-full flex justify-center">
            <LoadingButton
              onClick={() => {
                instance.logoutRedirect();
              }}
              variant="contained"
            >
              Logout
            </LoadingButton>
          </div>
        </div>
      </div>
    </>
  );

}

export default UnauthorizedAd;