import React from 'react';
import {Permission} from "./types";

type PermissionContextType = {
    isAllowedTo: (permission: Permission[]|undefined) => boolean|undefined;
}

const defaultBehaviour: PermissionContextType = {
    isAllowedTo: () => false
}

const PermissionContext = React.createContext<PermissionContextType>( defaultBehaviour);

export default PermissionContext;