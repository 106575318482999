import { useContext, useEffect, useState } from "react";
import { Country, useGetCountriesQuery } from "../../../../../generated/graphql";
import { AsyncSelect } from "../../async-select/AsyncSelect";
import { AsyncSelectData } from "../../async-select/AsyncSelectData";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../contexts/auth-context";

interface FormikFormSelectProps {
  fieldName: string;
  labelName: string;
  intialId?: number;
  disabled?:boolean;
  formik;
  type?: string;
  idFilterList?:number[];
}

// FormikProps<FormikConfig<FormikValues>>
const FormikCountryFormSelect = (props: FormikFormSelectProps) => {
  const userService = useContext(AuthContext)

  const { data: countries, loading: loadingCountries } = useGetCountriesQuery();

  const formikSetFieldValue = props.formik.setFieldValue;

  const [initialCountry, setInitialCountry] = useState<AsyncSelectData>();

  const { t } = useTranslation();

  useEffect(() => {
    if (countries?.countries) {
      let country: Country | undefined = undefined;
      if (props.intialId) {
        country = countries?.countries.find(
          (i) => i.id === props.intialId
        ) as Country;
      } else {
        country = countries?.countries.find(
          (i) => i.id === userService?.authContextData?.currentUser?.location?.company?.countryId
        ) as Country;
      }
      if (country) {
        let countrySelectData = new AsyncSelectData({
          title: country?.name,
          value: country?.id,
        });
        setInitialCountry(countrySelectData);
        setTimeout(()=>{
          formikSetFieldValue(props.fieldName, country?.id??0);
        },200);
       
      } else {
        setInitialCountry(undefined);
        formikSetFieldValue(props.fieldName, 0);
      }
    }
  }, [
    setInitialCountry,
    countries,
    formikSetFieldValue,
    props.fieldName,
    props.intialId,
    userService?.authContextData?.currentUser,
  ]);

  const selectedCountryChanged = (data: AsyncSelectData) => {
    formikSetFieldValue(props.fieldName, data?.value ?? 0);
  };


  return (
    <>
      <div className={`mt-3`}>
        <AsyncSelect
          label={t("modules.formik.country-label")}
          onItemSelected={selectedCountryChanged}
          disabled={props.disabled}
          onOpen={() => {console.log('')}}
          defaultSelectOption={initialCountry}
          data={countries?.countries?.filter(i=>{
            
            if(props.idFilterList && props.idFilterList.length > 0){
              return props.idFilterList?.includes(i.id)
            }
            return true;
          }).map(
            (i) =>
              new AsyncSelectData({
                title: i.name,
                value: i.id,
              })
          )}
          loadingData={loadingCountries}
          hasError={Boolean(
            props.formik.errors[props.fieldName] &&
              props.formik.touched[props.fieldName]
          )}
          className={`w-full`}
        />
      </div>
    </>
  );
};

export { FormikCountryFormSelect };
