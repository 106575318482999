import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import format from './i18n-format';
// import i18nSwitch from '../../pages/common/language-switcher/i18n';
// import moment from 'moment';
// import 'moment/locale/fr';

const debug = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? true : false;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: debug,
    fallbackLng: ["en", "fr"],
    backend: {
      loadPath: "/assets/locale/{{lng}}/{{ns}}.json",
    },
    ns: "translation",
    defaultNS: "translation",
    initImmediate: true, 
    interpolation: {
      format,
    },
  });
    
   i18n.services.formatter?.add('DATE_CURRENT', (value, lng) => {
        return new Date(value).toLocaleDateString(lng);
   });

    // i18n.on('languageChanged', (lng) => {
    //     i18nSwitch.changeLanguage(lng);
    //     moment.locale(lng);
    // });

export default i18n;