import React from 'react';
import {Permission} from './types';
import PermissionContext from './permission-context';

type Props = {
    permissions: Permission[]|undefined,
    children : any
}

const PermissionProvider: React.FunctionComponent<Props> = ({permissions, children}) => {
    const isAllowedTo = (cPermissions: Permission[]|undefined): boolean|undefined => permissions?.some((permission) => cPermissions?.includes(permission));
    return <PermissionContext.Provider value={{isAllowedTo}}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;