import { useMsal } from "@azure/msal-react";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  Location,
  User,
  useGetUserByEmailLazyQuery,
  usePermissionStateQuery,
} from "../../../generated/graphql";
import { LocalStorageKeys, globalAdminGroup } from "../../helpers/misc";
import AuthContext from "src/app/contexts/auth-context";

const UserPermissionService = () => {
  const { accounts } = useMsal();
  const [currentUser, setCurrentUser] = useState<User>();
  const [userLocations, setUserLocations] = useState<Location[] | undefined>();
  const [userADGroups, setUserADGroups] = useState<string[]>();
  const [permissions, setPermissions] = useState<string[] | undefined>();
  const [authToken, setAuthToken] = useState<string>();
  const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
  const { data: permissionStateData, updateQuery: permissionStateUpdateQuery } =
    usePermissionStateQuery();

  const authContext = useContext(AuthContext);

  console.log("AuthContext in user permission service", authContext);

  let [getUser, { data: userData, error: getUserError }] =
    useGetUserByEmailLazyQuery({
      fetchPolicy: "cache-first",
    });

  useEffect(() => {
    let _currUserString = localStorage.getItem(LocalStorageKeys.currentUser);
    let _currentUserObject = _currUserString
      ? JSON.parse(_currUserString)
      : undefined;
    if (_currentUserObject) setCurrentUser(_currentUserObject);
  }, [setCurrentUser]);

  useEffect(() => {
    if (userData?.userByEmail) return;
    let interval = setInterval(() => {
      let _token = localStorage.getItem("token");
      if (_token && !authToken) {
        setAuthToken(_token);
        clearInterval(interval);
      } else if (_token && authToken) {
        clearInterval(interval);
      }
    }, 200);
  }, [userData?.userByEmail, setAuthToken, authToken]);

  const loadUser = useCallback(
    (email) => {
      getUser({
        variables: {
          email: email,
        },
      });
    },
    [getUser]
  );

  //When the auth Token is changed the user is refetched
  useEffect(() => {
    if (authContext.authContextData?.currentUser) {
      loadUser(authContext.authContextData?.currentUser?.email);
    }

    if (userData?.userByEmail === null) {
      //navigate("/application-error");
    }
  }, [
    getUser,
    accounts,
    loadUser,
    userData,
    authToken,
    authContext.authContextData?.currentUser,
  ]);

  //When An error occurs in fetching the user.. retry again.
  useEffect(() => {
    if (getUserError) {
      setAuthToken(undefined);
    }
  }, [getUserError]);

  useEffect(() => {
    if (userData) {
      let user = userData?.userByEmail;
      if (
        user?.email?.toLocaleLowerCase() !==
        accounts[0]?.username?.toLocaleLowerCase()
      )
        return;
      setCurrentUser(user as User);

      try {
        let _userADGroups = JSON.parse(
          `${localStorage.getItem(LocalStorageKeys.userADGroups)}`
        );
        setUserADGroups(_userADGroups);
        if (
          _userADGroups?.includes(globalAdminGroup) &&
          !localStorage.getItem(LocalStorageKeys.ignoreGlobalAdmin)
        ) {
          setIsGlobalAdmin(true);
        } else {
          let locations = userData?.userByEmail?.locations;
          setUserLocations(locations as Location[]);
        }
      } catch (ex) {
        console.error(ex);
      }

      // setUserADGroups()
      let permissions = user.permissions.map((permission) => {
        return permission;
      });

      if (permissionStateData?.permissionState?.permissions)
        setPermissions(
          JSON.parse(permissionStateData?.permissionState?.permissions)
        );
      localStorage.setItem(LocalStorageKeys.currentUser, JSON.stringify(user));

      permissionStateUpdateQuery((result) => {
        return {
          ...result,
          permissionState: {
            permissions: JSON.stringify(permissions),
          },
        };
      });
    }
  }, [
    userData,
    permissionStateUpdateQuery,
    permissionStateData?.permissionState?.permissions,
    accounts,
  ]);

  return {
    permissions,
    currentUser,
    userADGroups,
    userLocations,
    isGlobalAdmin,
  };
};

export default UserPermissionService;
