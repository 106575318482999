import { useEffect, useState } from "react";
import { useGetUnitOfMeasureQuery } from "../../../../../generated/graphql";
import { AsyncSelect } from "../../async-select/AsyncSelect";
import { AsyncSelectData } from "../../async-select/AsyncSelectData";
import { useTranslation } from "react-i18next";

interface FormikFormSelectProps {
  fieldName: string;
  labelName: string;
  intialId?: number;
  formik;
  type?: string;
}

// FormikProps<FormikConfig<FormikValues>>
const FormikUnitOfMeasureFormSelect = (props: FormikFormSelectProps) => {
  const { data: UnitOfMeasures, loading: loadingUnitOfMeasures } =
    useGetUnitOfMeasureQuery();

  const formikSetFieldValue = props.formik.setFieldValue;

  const { t } = useTranslation();

  const [initialUnitOfMeasure, setInitialUnitOfMeasure] =
    useState<AsyncSelectData>();

  useEffect(() => {
    if (UnitOfMeasures?.unitsOfMeasure) {
      let unitOfMeasure = UnitOfMeasures?.unitsOfMeasure?.find(
        (i) => i.id === props.intialId
      );
      if (unitOfMeasure) {
        let unitOfMeasureSelectData = new AsyncSelectData({
          title: unitOfMeasure?.description,
          value: unitOfMeasure?.id,
        });
        setInitialUnitOfMeasure(unitOfMeasureSelectData);
        setTimeout(()=>{
          formikSetFieldValue(props.fieldName, unitOfMeasure?.id??0);
        },200);
      } else {
        setInitialUnitOfMeasure(undefined);
        formikSetFieldValue(props.fieldName, 0);
      }
    }
  }, [
    setInitialUnitOfMeasure,
    UnitOfMeasures,
    formikSetFieldValue,
    props.fieldName,
    props.intialId,
  ]);

  const selectedUnitOfMeasureChanged = (data: AsyncSelectData) => {
    formikSetFieldValue(props.fieldName, data.value);
  };


  return (
    <>
      <div className={`mt-3`}>
        <AsyncSelect
          label={t("modules.formik.uom-label")}
          onItemSelected={selectedUnitOfMeasureChanged}
          onOpen={() => {console.log('')}}
          defaultSelectOption={initialUnitOfMeasure}
          data={UnitOfMeasures?.unitsOfMeasure?.map(
            (i) =>
              new AsyncSelectData({
                title: i.description,
                value: i.id,
              })
          )}
          loadingData={loadingUnitOfMeasures}
          hasError={Boolean(
            props.formik.errors[props.fieldName] &&
              props.formik.touched[props.fieldName]
          )}
          className={`w-full`}
        />
        {/* {props.formik.errors[props.fieldName] &&
          props.formik.touched[props.fieldName] ? (
            <div className="errorMsg">
              {props.formik.errors[props.fieldName]}
            </div>
          ) : (
            ""
          )} */}
      </div>
    </>
  );
};

export { FormikUnitOfMeasureFormSelect };
