import { useCallback, useContext, useEffect, useState } from "react";
import {
  Vehicle,
  useGetVehiclesLookupByVehicleNumberLazyQuery,
} from "../../../../../generated/graphql";
import { AsyncSelect, AsyncSelectData } from "../../async-select/AsyncSelect";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../contexts/auth-context";
import Notifier, {
  emptyNotification,
  NotificationMessage,
} from "../../notifier/notifier";
import { VehicleTypeEnum } from "../../types/vehicle-types-enum";

interface VehicleLookupSelectProps {
  onVehicleSelected?: (item: Vehicle) => void;
  defaultSelectOption?: AsyncSelectData | null;
  hasError?: boolean;
}
const VehicleLookupSelect = ({
  onVehicleSelected,
  hasError,
  defaultSelectOption,
}: VehicleLookupSelectProps) => {
  const userService = useContext(AuthContext);
  const [_defaultSelectOption, _setDefaultSelectOption] = useState<
    AsyncSelectData | null | undefined
  >();
  const [notificationMessage, setNotificationMessage] =
    useState<NotificationMessage>();

  let [
    getVehiclesLookupLazily,
    { data: vehiclesLookupList, loading: loadingVehiclesLookup },
  ] = useGetVehiclesLookupByVehicleNumberLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  let vehiclesOpened = useCallback(
    (vehicleNo = "") => {
      getVehiclesLookupLazily({
        variables: {
          searchString: vehicleNo,
          vehcileTypeId: VehicleTypeEnum.Truck,
          countryIds: [
            Number(
              userService?.authContextData?.currentUser?.location?.company
                ?.countryId
            ),
          ],
        },
      });
    },
    [getVehiclesLookupLazily, userService?.authContextData?.currentUser]
  );

  const [selectedVehicle, setSelectedVehicle] = useState<AsyncSelectData>();

  const vehicleSelected = (data: AsyncSelectData) => {
    !!onVehicleSelected && onVehicleSelected(data?.extra as Vehicle);
    setSelectedVehicle(data);
  };

  useEffect(() => {
    if (defaultSelectOption) {
      _setDefaultSelectOption(defaultSelectOption);
    }
  }, [defaultSelectOption]);

  const { t } = useTranslation();

  const onHideNotification = () => {
    setNotificationMessage(emptyNotification(notificationMessage));
  };

  return (
    <div className="w-full">
      <div className="flex items-center w-full">
        <AsyncSelect
          placeholder={t("modules.lookup.vehicle.placeholder")}
          onItemSelected={vehicleSelected}
          onOpen={vehiclesOpened}
          defaultSelectOption={_defaultSelectOption}
          data={vehiclesLookupList?.vehicles?.nodes?.map(
            (i) =>
              new AsyncSelectData({
                title: `${i.vehicleNo} | ${i.supplier?.supplierName}`,
                value: i.id,
                tooltip: i.vehicleNo,
                enabled: !i.isBanned && i.isActive,
                extra: i,
              })
          )}
          inputValueChanged={(evt: any, value: string) => {
            getVehiclesLookupLazily({
              variables: {
                searchString: value,
                vehcileTypeId:VehicleTypeEnum.Truck,
                countryIds: [
                  Number(
                    userService?.authContextData?.currentUser?.location?.company
                      ?.countryId
                  ),
                ],
              },
            });
          }}
          loadingData={loadingVehiclesLookup}
          hasError={hasError}
          className="grow"
        />
      </div>

      <Notifier
        notificationMessage={notificationMessage}
        onHideNotifier={onHideNotification}
      />
    </div>
  );
};

export default VehicleLookupSelect;
