import { CogIcon } from "@heroicons/react/solid";
import moment from "moment";
import { useTranslation } from "react-i18next";

export interface NotificationCardProps {
  title: string;
  message: string;
  date: string;
  onClick: () => void;
}
function NotificationCard(props: NotificationCardProps) {
  const { t } = useTranslation();
  return (
    <div
      onClick={props.onClick}
      className={` w-full flex p-4 space-x-3 cursor-pointer border-t-[1px] border-gray-200 ${"bg-[#F7F9FC]"}`}
    >
      <div className="w-[10%] ">
        <CogIcon />
      </div>
      <div className=" w-[90%] space-y-2">
        <div className=" flex w-full justify-between items-center">
          <h3 className=" text-lg font-semibold">{props.title}</h3>
          <p>{moment(props.date).fromNow()}</p>
        </div>
        <p className=" text-[#758DA3]">{props.message}</p>
        <p className=" text-sm text-blue-500">{t("labels.view-details")}</p>
      </div>
    </div>
  );
}

export default NotificationCard;
