import  { useContext, useEffect, useMemo, useState } from "react";
import OperationType from "../../../common/types/operation-type-enum";
import FormikFormFieldBulder from "../../../common/formik-form-field/FormikFormFieldBuilder";
import { useTranslation } from "react-i18next";
import { useUpdateCustomerTrucksMutation, Vehicle } from "../../../../../generated/graphql";
import Notifier, { emptyNotification, NotificationMessage, NotificationType } from "src/app/pages/common/notifier/notifier";
import AuthContext from "src/app/contexts/auth-context";
import { formatApolloError } from "src/app/graphql/graphql-helpers";
import * as Yup from "yup";
import { useFormik } from "formik";

const UpsertCustomerTruck = (props: {
  customerId: number;
  selectedTruck: Vehicle;
  trucks: number[];
  operationType: OperationType;
  onOperationSuccess?: () => void;
  onClose: () => void;
  onStartEditClicked: () => void;
}) => {
    const { t } = useTranslation();  

    let [notificationMessage, setNotificationMessage] =
        useState<NotificationMessage>();

  const selectedTruck = useMemo(
    () => (props.selectedTruck ? props.selectedTruck : ({} as Vehicle)),
    [props.selectedTruck]
  );

  let userService = useContext(AuthContext);

  const [
    updateCustomerTrucks,
    {
        loading: updatingCustomerTrucks,
        data: updateCustomerTrucksData,
        error: updateCustomerTrucksError,
        reset: resetUpdateCustomerTrucksData,
    },
] = useUpdateCustomerTrucksMutation();


const onHideNotification = () => {
    if (notificationMessage?.type === NotificationType.success) {
        setTimeout(() => {
            if (props.onOperationSuccess) {
                props.onOperationSuccess();
                resetUpdateCustomerTrucksData();
            }
        }, 2);
    }
    setNotificationMessage(emptyNotification(notificationMessage));
};

useEffect(() => {
    if (updateCustomerTrucksError && !updatingCustomerTrucks) {
        let _err = updateCustomerTrucksError;
        setNotificationMessage({
            message: formatApolloError(_err),
            type: NotificationType.error,
        });
    }
}, [updatingCustomerTrucks, updateCustomerTrucksError]);

const newCustomerTruckSchema = Yup.object().shape({
    customerId: Yup.number(),
    id: Yup.number().required(t("modules.upsert.customer-truck.required.truck")),
});

const formik = useFormik({
    initialValues: {
        // Customer Truck initial values
        customerId: props.customerId,

        // Selected Truck details (For reading and deleting purpose)
        id: selectedTruck.id ?? 1,
        vehicleNumber: selectedTruck.vehicleNo ??"",
        eamId: selectedTruck.eamId ?? "",
        owner: selectedTruck.owner ?? "",
        active: selectedTruck.isActive ?? false,
    },
    validationSchema: newCustomerTruckSchema,
    onSubmit: (values) => {

        var newTrucksArrays = props.trucks;

        // Checking if the operation is delete, then remove the truck id from the array
        if(props.operationType === OperationType.delete){
            newTrucksArrays = newTrucksArrays.filter(function(x) { return x !== values.id; });
        } else{
            // Checking if the truck is already added to the customer
            if ( newTrucksArrays.indexOf(values.id) != -1) {
    
                setNotificationMessage({
                    message: t("modules.upsert.customer-truck.error.duplicate"),
                    type: NotificationType.error,
                });
    
                return;
            }
            else {
                newTrucksArrays.push(values.id);
            }
        }

        updateCustomerTrucks({
            variables: {
                customerId: values.customerId,
                vehicles: newTrucksArrays
            },
        });
    },
});

const { resetForm: formikResetForm, setValues: formikSetValues } = formik;

useEffect(() => {
    formikSetValues({
        customerId: props.customerId,
        id: selectedTruck.id ?? 0,
        vehicleNumber: selectedTruck.vehicleNo ??"",
        eamId: selectedTruck.eamId ?? "",
        owner: selectedTruck.owner ?? "",
        active: selectedTruck.isActive ?? false,
    });
}, [formikSetValues,
    props.customerId,
    selectedTruck.eamId,
    selectedTruck.id,
    selectedTruck.isActive,
    selectedTruck.owner,
    selectedTruck.vehicleNo
]);

useEffect(() => {
    if (updateCustomerTrucksData?.upsertCustomerVehicle.customer?.id && !updatingCustomerTrucks) {
        let message = "";
        if (props.operationType === OperationType.create)
            message = t("modules.upsert.customer-truck.message.added");
        else if (props.operationType === OperationType.delete)
            message = t("modules.delete.customer-truck.message.deleted");


        setNotificationMessage({
            message: message,
            type: NotificationType.success,
        });

        formikResetForm();
    }
}, [
    updateCustomerTrucksData,
    updatingCustomerTrucks,
    formikResetForm,
    props.operationType,
    t,
]);

    const deleteTruckFromCustomer = () => {
        formik.submitForm();
    };

  return (
    <>
      <div>
        <FormikFormFieldBulder
          formik={formik}
          loading={updatingCustomerTrucks}
          itemName="Vehicle"
          onStartEditClicked={props.onStartEditClicked}
          selectedItem={selectedTruck}
          operationType={props.operationType}
          onClose={props.onClose}
          allowEdit={
            userService.authContextData?.isGlobalAdmin ||
            (userService.authContextData?.currentUser?.location?.company.countryId ===
                selectedTruck?.countryId)
          }
          onDeleteClicked={deleteTruckFromCustomer}
          fieldOptions={[
            {
              fieldName: "id",
              labelName: "Assignment of Vehicle",
              type: "vehicleFormSelect"
            }
          ]}
          excludeFields={[
            "updatedAt",
            "createdAt"
        ]}
          fieldConfig={{

          }}
        />
        <Notifier
            notificationMessage={notificationMessage}
            onHideNotifier={onHideNotification}
        />
      </div>
    </>
  );
};

export default UpsertCustomerTruck;
