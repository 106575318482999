import { useCallback, useEffect, useState } from "react";
import {
  DescriptionValue,
  useGetDescriptionValuesLazyQuery,
} from "../../../../../generated/graphql";
import { AsyncSelect, AsyncSelectData } from "../../async-select/AsyncSelect";
import { useTranslation } from "react-i18next";
import Notifier, {
  emptyNotification,
  NotificationMessage,
} from "../../notifier/notifier";

interface DescriptionLookupSelectProps {
  onDescriptionSelected?: (item: DescriptionValue) => void;
  defaultSelectOption?: AsyncSelectData | null;
  hasError?: boolean;
}
const DescriptionLookupSelect = ({
  onDescriptionSelected,
  hasError,
  defaultSelectOption,
}: DescriptionLookupSelectProps) => {
  const [_defaultSelectOption, _setDefaultSelectOption] = useState<
    AsyncSelectData | null | undefined
  >();
  const [notificationMessage, setNotificationMessage] =
    useState<NotificationMessage>();

  let [
    getDescriptionLookupLazily,
    { data: descriptionLookupList, loading: loadingDescriptionLookup },
  ] = useGetDescriptionValuesLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  let descriptionOpened = useCallback(
    (description = "") => {
      getDescriptionLookupLazily({
        variables: {
          searchString: description,
        },
      });
    },
    [getDescriptionLookupLazily]
  );

  const [selectedDescription, setSelectedDescription] = useState<AsyncSelectData>();

  const descriptionSelected = (data: AsyncSelectData) => {
    !!onDescriptionSelected && onDescriptionSelected(data?.extra as DescriptionValue);
    setSelectedDescription(data);
  };

  useEffect(() => {
    if (defaultSelectOption) {
      _setDefaultSelectOption(defaultSelectOption);
    }
  }, [defaultSelectOption]);

  const { t } = useTranslation();

  const onHideNotification = () => {
    setNotificationMessage(emptyNotification(notificationMessage));
  };

  return (
    <div className="w-full">
      <div className="flex items-center w-full">
        <AsyncSelect
          label={t("modules.upsert.location.form.value-to-use-as-item-description")}
          placeholder={t("modules.lookup.description.placeholder")}
          onItemSelected={descriptionSelected}
          onOpen={descriptionOpened}
          defaultSelectOption={_defaultSelectOption}
          data={descriptionLookupList?.descriptionValues?.map(
            (i) =>
              new AsyncSelectData({
                title: `${i.description}`,
                value: i.id,
                tooltip: i.description,
                extra: i,
              })
          )}
          inputValueChanged={(evt: any, value: string) => {
            getDescriptionLookupLazily({
              variables: {
                searchString: value,
              },
            });
          }}
          loadingData={loadingDescriptionLookup}
          hasError={hasError}
          className="grow"
        />
      </div>

      <Notifier
        notificationMessage={notificationMessage}
        onHideNotifier={onHideNotification}
      />
    </div>
  );
};

export default DescriptionLookupSelect;
