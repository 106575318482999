const format = (value, format, lng) =>
  format.startsWith("date") ? formatDate(value, format, lng) : value;

const formatDate = (value, format, lng) => {
  const options = toOptions(format);
  return options === null
    ? value
    : new Intl.DateTimeFormat(lng, options).format(value);
};

const toOptions = (format) => {
  if (format.trim() === "date") {
    return {};
  } else {
    try {
      return JSON.parse(toJsonString(format));
    } catch (error) {
      console.error(error);
      return null;
    }
  }
};

const toJsonString = (format) => {
  const inner = format
    .trim()
    .replace("date", "")
    .replace("(", "")
    .replace(")", "")
    .split(";")
    .map((param) =>
      param
        .split(":")
        .map((name) => '"' + name.trim() + '"')
        .join(":")
    )
    .join(",");

  return "{" + inner + "}";
};

export default format;
