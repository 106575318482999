import { Button } from "@mui/material";
import OperationType from "../types/operation-type-enum";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const SideBoxFooter = (props: {
  resetForm: () => void;
  onClose?: () => void;
  onStartEditClicked?: () => void;
  onDeleteClicked?: () => void;
  isBusy: boolean;
  operationType?: OperationType;
  allowEdit: boolean;
  editButtonText?:string;
  itemName: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className="mt-5 flex justify-between sticky -bottom-1 pb-1 bg-white z-10 pr-4">
      <div className="flex flex-col grow">
        {props.operationType === OperationType.read &&
          props.allowEdit &&
          props.onStartEditClicked && (
            <Button
              startIcon={<EditIcon />}
              onClick={props.onStartEditClicked}
              type="button"
              variant="contained"
              className="!m-2"
            >
              {props.editButtonText??t("modules.form.edit")}
            </Button>
          )}
        {props.operationType === OperationType.delete &&
          props.allowEdit &&
          props.onDeleteClicked && (
            <Button
              startIcon={<DeleteIcon />}
              onClick={props.onDeleteClicked}
              type="button"
              variant="contained"
              color="error"
              className="!m-2"
            >
              {t("modules.form.delete")}
            </Button>
          )}
      </div>

      {(props.operationType === OperationType.update ||
        props.operationType === OperationType.create) && (
        <Button onClick={props.onClose} type="button" className="mr-14">
          {t("modules.form.discard")}
        </Button>
      )}
      {(props.operationType === OperationType.update ||
        props.operationType === OperationType.create) && (
        <LoadingButton
          className="justify-end"
          variant="contained"
          type="submit"
          style={{display:"inline-block",whiteSpace:'nowrap',padding:10}}
          loading={props.isBusy}
        >
          {props.operationType === OperationType.create &&
            `${t("modules.form.new")}`}

          {props.operationType === OperationType.update &&
            (props.editButtonText??`${t("modules.form.update")}`)}
        </LoadingButton>
      )}
    </div>
  );
};

export default SideBoxFooter;
