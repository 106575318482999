import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext, { AuthContextType } from "src/app/contexts/auth-context";
import { useAuth } from "src/app/features/auth/hooks/useAuth";
import { getAuthClaimsEntity } from "src/app/utils";
import { User, useGetUserByEmailLazyQuery } from "src/generated/graphql";

export const AuthContextProvider = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  const isAuthRoute = location.pathname === "/auth";
  const navigate = useNavigate();
  const [authContextData, setAuthContextData] = useState<AuthContextType>();
  const { refreshToken, accessToken } = authContextData ?? {};

  const [
    getUserByEmail,
    { data: userData, loading: userLoading, error: userFetchError },
  ] = useGetUserByEmailLazyQuery();

  const getLoggedInUser = useCallback(
    (email) => {
      getUserByEmail({
        variables: {
          email: email,
        },
      });
    },
    [getUserByEmail]
  );

  const authValue = useMemo(
    () => ({ authContextData, setAuthContextData, getLoggedInUser }),
    [authContextData, setAuthContextData,getLoggedInUser]
  );

  const isTokenExpired = useCallback((token) => {
    const decodedToken = getAuthClaimsEntity(token);
    let expired = decodedToken?.exp??0 * 1000 < Date.now();
    debugger;
    return expired;
  },[]);

  useEffect(() => {
    const accessToken = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refresh_token");

    if (accessToken && refreshToken) {
      const decodedToken = getAuthClaimsEntity(accessToken);
      getLoggedInUser(decodedToken?.email as string);
    }
  }, [getLoggedInUser]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        authContextData?.refreshToken &&
        !isTokenExpired(authContextData?.refreshToken)
      ) {
        auth
          .refreshAccessToken(authContextData?.refreshToken)
          .then((response) => {
            setAuthContextData({
              ...(authContextData ?? {}),
              accessToken: response?.access_token,
              refreshToken: response?.refresh_token,
            });
          });
      } else {
        auth.logout(); // Logout if refresh token is expired
        navigate("login");
      }
    }, 15 * 60 * 1000); // Check token every 15 minutes

    return () => clearInterval(interval);
  }, [refreshToken, accessToken, setAuthContextData, auth.refreshAccessToken, auth.logout, auth, authContextData, isTokenExpired, navigate]);


  useEffect(() => {
    if (
      !userLoading &&
      userData?.userByEmail?.id &&
      !authContextData?.currentUser
    ) {
      localStorage.setItem("currentUser", JSON.stringify(userData.userByEmail));
      setAuthContextData?.({
        ...(authContextData ?? {}),
        currentUser: userData?.userByEmail as User,
      } as AuthContextType);
      setTimeout(() => {
        if (isAuthRoute) navigate("/landing");
      }, 300);
    }

    if (userFetchError?.graphQLErrors?.length ?? 0 > 0) {
      //setAuthContextData({} as AuthContextType);
      auth.logout();

      navigate("/login");
    }
  }, [userLoading, userData?.userByEmail, userFetchError?.graphQLErrors, authContextData, navigate, isAuthRoute, auth]);

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};
