import { ApolloError } from "@apollo/client";

export const formatApolloError = (apolloError: ApolloError) => {
  let errorMessage = "";
  let networkErrors = (apolloError?.networkError as any)?.result?.errors;
  if (networkErrors) {
    for (const error of networkErrors) {
      errorMessage += error.message;
      if (error?.extensions) {
        if (Object.keys(error.extensions).length > 0)
          for (const extensionKey of Object.keys(error.extensions)) {
            if (extensionKey === "code" || extensionKey === "field") continue;
            if (!errorMessage) errorMessage = "";
            errorMessage += `\n ${error.extensions[extensionKey]}`;
          }
      }
    }
  } else if (apolloError.graphQLErrors.length === 0)
    errorMessage += apolloError?.message ?? "An unknown error occured";
  else {
    for (const error of apolloError.graphQLErrors) {
      errorMessage += error.message;
      if (error?.extensions) {
        let objKeys = Object.keys(error.extensions);
        if (objKeys.length > 0) errorMessage = "";
        let counter = 0;
        for (const extensionKey of objKeys) {
          counter++;
          if (extensionKey === "code") continue;
          errorMessage += `\n ${error.extensions[extensionKey]}${
            objKeys.length === counter ? "." : ", "
          }`;
        }
      }
    }
  }

  return errorMessage;
};