import { useState } from "react";
import { LocationTypeEnum } from "./LocationTypeEnum";
import { Autocomplete, TextField } from "@mui/material";

interface LocationTypeLookupSelectProps {
  onLocationTypeSelected?: (item: string) => void;
  initialValue?: string;
}
const LocationTypeLookupSelect = ({
  onLocationTypeSelected,
  initialValue,
}: LocationTypeLookupSelectProps) => {

  const [locationType, setLocationType] = useState<string>(LocationTypeEnum[initialValue ?? LocationTypeEnum.Plant]);

  const locationTypeChange = (value: any) => {
    !!onLocationTypeSelected && onLocationTypeSelected(LocationTypeEnum[value]);
    setLocationType(value);
  };

  return (
    <Autocomplete
      onChange={locationTypeChange}
      value={locationType}
      disablePortal
      options={Object.keys(LocationTypeEnum)}
      sx={{ marginTop: "20px" }}
      renderInput={(params) => (
        <TextField {...params} label="Type" />
      )}
    />
  );
};

export default LocationTypeLookupSelect;
