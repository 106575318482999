import { Env } from "../features/auth/types";

const env = Env[process.env.REACT_APP_APP_ENV as keyof typeof Env] ?? Env.LOCAL;
const getEnv = () => env;

const getApiBaseUrl = () => {
    const { hostname, origin } = window.location;
    const graphqlEndpoint = hostname === 'localhost' ? '/api/graphql' : `${origin}/api/graphql`;
    const wsEndpoint = hostname === 'localhost' ? 'wss://localhost:7196/api/graphql' : `wss://${hostname}/api/graphql/`;
    return { graphqlEndpoint: graphqlEndpoint, wsEndpoint, origin };
  };
  
  const getActiveDirectoryConfig = () => {
    const hostname = window.location.hostname;
    let redirectUri = `https://${hostname}/auth`;
    let clientId = 'f799ea1c-28be-4745-b422-f580a2e43afa';
    let scope = 'api://karibu-karibu-frontend-frontend-dev/karibu';
  
    if (hostname === 'localhost') {
      redirectUri = 'http://localhost:3000/';
    }
    else if (hostname.includes('qa')) {
      redirectUri = `https://${hostname}/auth`;
      clientId = 'f799ea1c-28be-4745-b422-f580a2e43afa';
      scope = 'api://karibu-karibu-frontend-frontend-qa/karibu';
    }
    else if (hostname.includes('mizani-uat')) {
      redirectUri = `https://${hostname}/auth`;
      clientId = 'f799ea1c-28be-4745-b422-f580a2e43afa';
      scope = 'api://karibu-karibu-frontend-frontend-uat/karibu';
    }
    else if (hostname.includes('karibu.heidelbergmaterials.com')) {
      clientId = '';
      scope = 'api://karibu-admin-web-prod/AEMYM';
    }

    return { redirectUri, clientId, scope };
  };
  
  const getKaribuAdGroups = () => {
    const hostname = window.location.hostname;
    let groups: string[] = [];
    if (hostname.includes('qa')) {
      groups = ['GRP Mizani YM GLB Admin', 'GRP Mizani YM GLB Users','GRP AEM TECHUB Users'];
    }
    else if (hostname.includes('mizani-uat')) {
      groups = ['GRP Mizani YM GLB Admin', 'GRP Mizani YM GLB Users','GRP AEM TECHUB Users'];
    }
    else if (hostname.includes('mizani.heidelbergmaterials.com')) {
      groups = ['GRP Mizani YM GLB Admin', 'GRP Mizani YM GLB Users','GRP AEM TECHUB Users'];
    }else{
      groups = ['GRP AEM TECHUB Users'];
    }
    return groups;
  
  }
  
  export { getApiBaseUrl, getActiveDirectoryConfig ,getKaribuAdGroups, getEnv };
  