import { useState } from "react";
import DropDown from "../drop-down/drop-down";
import { DropdownItemSelectedParams } from "../drop-down/DropdownItemSelectedParams";
import { SearchBarSearchParams } from "./SearchBarSearchParams";
import { useTranslation } from "react-i18next";

type Props = {
  searchList: string[];
  onSearch: (searchParams: SearchBarSearchParams) => void;
  onTextChanged?: (searchParams: SearchBarSearchParams) => void;
  hideDropdown?: boolean;
};

const SearchBar = ({
  searchList,
  onSearch,
  onTextChanged,
  hideDropdown,
}: Props) => {
  const [selectedDropDownValue, setSelectedDropdownValue] = useState("");
  const [searchText, setSearchText] = useState("");

  const onDropdownItemSelected = (
    itemSelectedParams: DropdownItemSelectedParams
  ) => {
    setSelectedDropdownValue(itemSelectedParams.selectedItem);
  };

  const textChanged = (evt) => {
    setSearchText(evt.target.value);

    if (onTextChanged)
      onTextChanged({
        text: evt.target.value,
        selectedSearchItem: selectedDropDownValue,
        eventParams: evt,
      });
  };

  const searchClicked = (evt) => {
    onSearch({
      text: searchText,
      selectedSearchItem: selectedDropDownValue,
      eventParams: evt,
    });
  };

  const { t } = useTranslation();

  return (
    <>
      <div className="search-bar flex items-center w-full"
       style={{
        zIndex: 20
       }}>
        <div className="relative w-full max-w-full transform pl-4 transition-all opacity-100 scale-100">
          <div
            className="rounded-lg bg-white border-gray-200 border-2"
            id="headlessui-dialog-panel-35"
          >
            <div className="relative flex items-center min-h-[1.4375em]">
              <DropDown
                className={hideDropdown ? "hidden" : ""}
                searchList={searchList}
                onItemSelected={onDropdownItemSelected}
                
              />

              <div
                className={`seperator bg-gray-200 h-12 mx-2 ${
                  hideDropdown ? "hidden" : ""
                }`}
                style={{ width: "1px" }}
              ></div>

              <input
                value={searchText}
                onChange={textChanged}
                onKeyUp={(evt) => {
                  if (evt.code === "Enter") {
                    searchClicked(evt);
                  }
                }}
                className="block flex-grow text-ellipsis appearance-none bg-transparent pl-2 lg:text-[1rem] placeholder:text-[1rem] text-slate-900 placeholder:text-slate-600 focus:outline-none sm:text-sm sm:leading-6"
                placeholder={
                  selectedDropDownValue
                    ? `${t(
                        "search-bar.search-by"
                      )} ${t(selectedDropDownValue)}`
                    : `${t("search-bar.search")}`
                }
                id="headlessui-combobox-input-36"
                type="text"
                style={{ caretColor: "rgb(107, 114, 128)" }}
              />

              <div
                className="search-icon h-12 flex items-center justify-center p-4"
                onClick={searchClicked}
              >
                <svg
                  className="cursor-pointer w-8 h-8 fill-slate-400"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20.47 21.53a.75.75 0 1 0 1.06-1.06l-1.06 1.06Zm-9.97-4.28a6.75 6.75 0 0 1-6.75-6.75h-1.5a8.25 8.25 0 0 0 8.25 8.25v-1.5ZM3.75 10.5a6.75 6.75 0 0 1 6.75-6.75v-1.5a8.25 8.25 0 0 0-8.25 8.25h1.5Zm6.75-6.75a6.75 6.75 0 0 1 6.75 6.75h1.5a8.25 8.25 0 0 0-8.25-8.25v1.5Zm11.03 16.72-5.196-5.197-1.061 1.06 5.197 5.197 1.06-1.06Zm-4.28-9.97c0 1.864-.755 3.55-1.977 4.773l1.06 1.06A8.226 8.226 0 0 0 18.75 10.5h-1.5Zm-1.977 4.773A6.727 6.727 0 0 1 10.5 17.25v1.5a8.226 8.226 0 0 0 5.834-2.416l-1.061-1.061Z"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBar;
