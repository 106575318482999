import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { msalConfig } from "./app/config/auth-config";
import { ApolloProvider, client } from './app/graphql/bootstrap';
import { AppRoutes } from "./app/routing/AppRoutes";
import i18n from './app/services/i18n/i18n';
import './index.scss';
import reportWebVitals from './reportWebVitals';

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <MsalProvider instance={msalInstance}>
      <ApolloProvider client={client}>
        <I18nextProvider i18n={i18n}>
            <AppRoutes />
        </I18nextProvider>
      </ApolloProvider>
    </MsalProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
