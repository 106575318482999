import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import {
  PublicClientApplication,
  SilentRequest,
  BrowserAuthError,
} from "@azure/msal-browser";
import { loginRequest, msalConfig } from "../config/auth-config";
import { getApiBaseUrl } from "../config/env";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { getAuthClaimsEntity } from "../utils";
import { useAuth } from "../features/auth/hooks/useAuth";
const { graphqlEndpoint } = getApiBaseUrl();

const uploadLink = createUploadLink({
  uri: graphqlEndpoint,
  headers: {
    "GraphQL-Preflight": "1",
    Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
  },
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem("token");
  const refresh_token = localStorage.getItem("refresh_token") as string;

  if (token) {
    const decodedToken = getAuthClaimsEntity(token);
    const currentTime = Date.now() / 1000;

    if (!decodedToken?.exp || decodedToken?.exp < currentTime) {
      console.log("Token expired, refreshing token: " + refresh_token);
      // Assume refreshToken is an async function that refreshes the token
      const authMethods = useAuth();
      const response = await authMethods.refreshAccessToken(refresh_token);
      if (response) {
        // Update the token in storage
        localStorage.setItem("token", response.access_token);
        // Update the authorization header with the new token
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${response.access_token}`,
          },
        };
      } else {
        console.log("token refresh failed");
        // Handle the case where token refresh fails, e.g., redirect to /
        // router.replace("/");
        return {
          headers: {
            ...headers,
          },
        };
      }
    } else {
      console.log("Token not expired, using current token");
    }
  } else {
    console.log("No token found");
  }

  const head = {
    ...headers,
    authorization: token ? `Bearer ${token}` : "",
  };

  return {
    headers: head,
  };
});

const getNewToken = async () => {
  const publicClientApplication = new PublicClientApplication(msalConfig);
  const account = publicClientApplication.getAllAccounts()[0];

  const accessTokenRequest = {
    ...loginRequest,
    account: account,
  } as SilentRequest;
  return publicClientApplication
    .acquireTokenSilent(accessTokenRequest)
    .then(function (accessTokenResponse) {
      let accessToken = accessTokenResponse.accessToken;
      localStorage.setItem("token", accessToken);
      return accessToken;
    })
    .catch(function (error) {
      if (error instanceof BrowserAuthError) {
        if (error.errorCode === "no_account_error") {
          console.error(error);
        } else {
          publicClientApplication.loginRedirect(loginRequest);
        }
      } else {
        localStorage.clear();
        publicClientApplication.logoutRedirect();
      }
    });
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      const oldHeaders = operation.getContext().headers;
      for (let err of graphQLErrors) {
        switch (err?.extensions?.code) {
          case "804":
            // Modify the operation context with a new token
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${getNewToken()}`,
              },
            });
            // Retry the request, returning the new observable
            return forward(operation);
          case "404":
            console.log(JSON.stringify(err, null, 2));
        }
      }
    }
    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error, operation) => {
      return !!error && operation.operationName !== "specialCase";
    },
  },
  delay: (count) => {
    return count * 10 * Math.random();
  },
});

const customPaginationMerge = (existing, incoming, obj) => {
  if (
    (obj.variables as Object).hasOwnProperty("args") ||
    (obj.variables as Object).hasOwnProperty("searchString") ||
    (obj.variables as Object).hasOwnProperty("id")
  ) {
    return incoming;
  }

  if (obj.variables?.cursor && existing) {
    return merge();
  }

  function merge() {
    if (!incoming?.nodes || incoming?.nodes?.length < 1) return existing;
    let newObj = {
      ...existing,
      ...incoming,
      nodes: mergeByKey(existing?.nodes ?? [], incoming?.nodes ?? [], "__ref"),
    };
    return newObj;
  }

  return incoming;
};

// join all links that depends on http protocol
const httplink = ApolloLink.from([authLink, errorLink, retryLink, uploadLink]);

const client = new ApolloClient({
  // there can only be one "terminating" link for apollo-upload-client
  link: httplink,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        keyFields: ["id", "userName"],
      },
      DispatchTicket: {
        keyFields: ["logicalId"],
      },
      Query: {
        fields: {
          products: {
            keyArgs: ["id", "where"],
            merge: customPaginationMerge,
          },
          salesVouchers: {
            keyArgs: ["id", "where"],
            merge: customPaginationMerge,
          },
          payments: {
            keyArgs: ["id", "where"],
            merge: customPaginationMerge,
          },
          predefinedReportFilters: {
            keyArgs: ["id", "where"],
            merge: customPaginationMerge,
          },
          trucks: {
            keyArgs: ["id", "where"],
            merge: customPaginationMerge,
          },
          tickets: {
            keyArgs: ["where"],
            merge: customPaginationMerge,
          },
          manualWeightHistory: {
            keyArgs: ["where"],
            merge: customPaginationMerge,
          },
          salesDispatchTickets: {
            keyArgs: ["id", "where"],
            merge: customPaginationMerge,
          },
          drivers: {
            keyArgs: ["id", "where"],
            merge: customPaginationMerge,
          },
          suppliers: {
            keyArgs: ["id", "where"],
            merge: customPaginationMerge,
          },
          customers: {
            keyArgs: ["id", "where"],
            merge: customPaginationMerge,
          },
          countries: {
            keyArgs: ["id", "where"],
            merge: customPaginationMerge,
          },
          companies: {
            keyArgs: ["where", "id", "first"],
            merge: customPaginationMerge,
          },
          weightWarningReports: {
            keyArgs: ["where"],
            merge: customPaginationMerge,
          },
          locationProducts: {
            keyArgs: ["id"],
            merge: customPaginationMerge,
          },
          locations: {
            keyArgs: ["id", "where", "where.companyId.in", "first"],
            merge: customPaginationMerge,
          },
          scaleConfigs: {
            keyArgs: ["id"],
            merge: customPaginationMerge,
          },
          users: {
            keyArgs: ["email", "id", "searchString"],

            merge: (existing, incoming, obj) => {
              if (
                (obj.variables?.cursor || !obj.variables?.take) &&
                obj.field?.alias?.value === "allUsers"
              ) {
                if (!incoming?.nodes || incoming?.nodes?.length < 1)
                  return existing;
                let newObj = {
                  ...existing,
                  ...incoming,
                  nodes: mergeByKey(
                    existing?.nodes ?? [],
                    incoming?.nodes ?? [],
                    "__ref"
                  ),
                };
                return newObj;
              }
              return incoming;
            },
          },
        },
      },
    },
  }),
});

const mergeByKey = (a, b, key) => {
  function x(a) {
    a.forEach(function (b) {
      if (!(b[key] in obj)) {
        obj[b[key]] = obj[b[key]] || {};
        array.push(obj[b[key]]);
      }
      Object.keys(b).forEach(function (k) {
        obj[b[key]][k] = b[k];
      });
    });
  }

  var array: any[] = [],
    obj = {};

  x(a);
  x(b);
  return array;
};

export { ApolloProvider, client };
