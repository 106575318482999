import { SearchBarSearchParams } from "../search-bar/SearchBarSearchParams";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "../search-bar/SearchBar";

const ConfigurationShell = ({
  searchItems,
  onSearch,
  title,
  onAddButtonClick,
  addButtonLabel,
  addButtonClassName,
  sideBoxShown,
  hideSearchButton = false,
  upsertComponent,
  showBackButton = true,
  gridComponent,
  allowUpsert = true,
  previousPage,
  onNavigateBack
}: {
  searchItems: string[];
  onSearch: (searchParams: SearchBarSearchParams) => void;
  title: string;
  sideBoxShown: boolean;
  addButtonLabel?: string;
  showBackButton?: boolean;
  addButtonClassName?: string;
  hideSearchButton?: boolean;
  onAddButtonClick?: () => void;
  upsertComponent: () => JSX.Element;
  gridComponent: () => JSX.Element;
  allowUpsert?: boolean;
  previousPage?: string;
  onNavigateBack?: () => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-1 flex-col h-full">
      <div className="flex flex-row justify-between items-center pl-4 pr-6 min-h-1/6 top-bar w-full">
        {!(hideSearchButton && !showBackButton) && (
          <div className="flex-col  ">
            {showBackButton && (
              <button
                onClick={() => { onNavigateBack ? onNavigateBack() : navigate(-1) }}
                className="tpr-name text-white pt-6 pb-2 text-lg flex cursor-pointer items-center"
              >
                <ArrowBackIosNewIcon />
                <p className="pl-4">
                  {previousPage
                    ? t("labels.back-to") + t(`${previousPage}`)
                    : t(
                      "modules.configuration.customers.details.back-to-configuration"
                    )}
                </p>
              </button>
            )}
            <p className="text-3xl font-semibold text-white pl-10">
              {t(title)}
            </p>
          </div>
        )}
      </div>
      <div
        className={`card rounded-md flex flex-grow overflow-hidden relative`}
      >
        <div className="card__left mr-4 rounded-md bg-white">
          <div className="card__left__filters flex justify-between h-full flex-1 ">
            {!hideSearchButton && (
              <div>
                <SearchBar searchList={searchItems} onSearch={onSearch} />
              </div>
            )}
            {hideSearchButton && <div></div>}
            {(!!onAddButtonClick || !sideBoxShown) && allowUpsert && (
              <button
                className={` flex items-center flex-row gap-2 ${addButtonClassName} h-12 z-10`}
                onClick={onAddButtonClick}
              >
                <AddIcon
                  sx={{
                    "&": {
                      color: "rgb(39,72,103)",
                      cursor: "pointer",
                    },
                  }}
                  fontSize="large"
                />
                {!!addButtonLabel && (
                  <span className=" text-base text-yardDeepBlue font-semibold ">
                    {addButtonLabel}
                  </span>
                )}
              </button>
            )}
          </div>
          <>{gridComponent()}</>
        </div>
        {allowUpsert && sideBoxShown && <>{upsertComponent()}</>}
      </div>
    </div>
  );
};

export default ConfigurationShell;
