import PulseLoader from "react-spinners/PulseLoader";
const Loader = ({ loaderSize = 10 }) => {
  return (
    <div key={"loader"} className="centered sweet-loading">
      <PulseLoader
        color="#2A7DB3"
        margin={5}
        size={loaderSize}
        speedMultiplier={1}
      />
    </div>
  );
};

export default Loader;
