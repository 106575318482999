import { Shimmer } from "react-shimmer";

export const SplashScreen = () => {
  return (
    <>
      <div className="w-full h-full flex space-x-4 pt-[3%] pb-[2%] px-8 ">
        <div className={`rounded-md w-full h-full overflow-hidden`}>
          <Shimmer width={window.innerWidth} height={window.innerHeight} />
        </div>
      </div>
    </>
  );
};
