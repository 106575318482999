export enum FCMNotificationTypeEnum {
  EPaymentAlert = "1",
  ManualPaymentRequest = "2",
  ActionRequired = "3",
  OrderApproval = "4",
  NewSalesVoucher = "5",
  Quotation = "6",
  ApprovedPayment = "7",
  Default = "8",
}
