import { useContext, useEffect, useState } from "react";
import { useGetProductsByCompanyQuery } from "../../../../../generated/graphql";
import { AsyncSelect } from "../../async-select/AsyncSelect";
import { AsyncSelectData } from "../../async-select/AsyncSelectData";
import { useTranslation } from "react-i18next";
import AuthContext from "src/app/contexts/auth-context";

interface FormikFormSelectProps {
  fieldName: string;
  labelName?: string;
  intialId?: number;
  formik;
  type?: string;
  showLabel?: boolean;
}

const FormikProductFormSelect = (props: FormikFormSelectProps) => {
  const userService = useContext(AuthContext);

  const { data: productData, loading: loadingProduct } =
    useGetProductsByCompanyQuery({
      variables: {
        countryId: Number(
          userService.authContextData?.currentUser?.location?.company?.countryId
        ),
        companyId: Number(
          userService.authContextData?.currentUser?.location?.companyId ||
            userService.authContextData?.currentUser?.location?.company?.id
        ),
      },
    });
  const formikSetFieldValue = props.formik.setFieldValue;
  const { t } = useTranslation();

  const [initialProduct, setInitialProduct] = useState<AsyncSelectData>();

  useEffect(() => {
    if (productData?.products) {
      console.log(productData?.products);
      let product = productData?.products?.nodes?.find(
        (i) => i.id === props.intialId
      );
      if (product) {
        let productSelectData = new AsyncSelectData({
          title: product?.title,
          value: product?.id,
        });
        setInitialProduct(productSelectData);
        setTimeout(() => {
          formikSetFieldValue(props.fieldName, product?.id ?? 0);
        }, 200);
      } else {
        setInitialProduct(undefined);
        formikSetFieldValue(props.fieldName, 0);
      }
    }
  }, [
    setInitialProduct,
    productData,
    formikSetFieldValue,
    props.fieldName,
    props.intialId,
  ]);

  const selectedProductChanged = (data: AsyncSelectData) => {
    formikSetFieldValue(props.fieldName, data.value);
  };

  return (
    <>
      <div className={`mt-3`}>
        <AsyncSelect
          label={props.showLabel ? t("modules.formik.product") : ""}
          onItemSelected={selectedProductChanged}
          onOpen={() => {
            console.log("");
          }}
          defaultSelectOption={initialProduct}
          data={productData?.products?.nodes?.map(
            (i) =>
              new AsyncSelectData({
                title: i.title,
                value: i.id,
              })
          )}
          loadingData={loadingProduct}
          hasError={Boolean(
            props.formik.errors[props.fieldName] &&
              props.formik.touched[props.fieldName]
          )}
          className={`w-full`}
        />
      </div>
    </>
  );
};

export { FormikProductFormSelect };
