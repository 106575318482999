import { Alert, AlertColor, Snackbar } from "@mui/material";
import { SyntheticEvent } from "react";

interface props {
  notificationMessage?: NotificationMessage;
  onClick?: () => void;
  onHideNotifier?: (event: Event | SyntheticEvent<any, Event>) => void;
  autoHideDuration?: number;
  position?: { vertical; horizontal };
}

export interface NotificationMessage {
  message: string;
  type: NotificationType;
}

export enum NotificationType {
  success = "success",
  info = "info",
  error = "error",
  warning = "warning",
}

const Notifier = ({
  notificationMessage,
  onHideNotifier,
  autoHideDuration = 2000,
  onClick,
  position = { vertical: "top", horizontal: "center" },
}: props) => {
  return (
    <>
      <Snackbar
        open={!!notificationMessage?.message}
        autoHideDuration={
          notificationMessage?.type === NotificationType.success
            ? autoHideDuration
            : 5000
        }
        onClose={(evt) => {
          onHideNotifier?.(evt);
        }}
        anchorOrigin={position}
        sx={{
          "&": {
            top: `38px !important`,
            cursor: onClick ? "pointer" : "default",
          },
        }}
        // action={action}
      >
        <Alert
          variant="filled"
          onClose={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            onHideNotifier?.(evt);
          }}
          onClick={onClick}
          severity={
            (notificationMessage?.type?.toLowerCase() ?? "info") as AlertColor
          }
          className="flex items-center text-lg"
          sx={{
            "&": {
              fontSize: `1.3rem !important`,
              transform: `scale(0.9) !important`,
              borderRadius: `0px 0px 5px 5px !important`,
            },
          }}
        >
          {notificationMessage?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export const emptyNotification = (
  notificationMessage: NotificationMessage | undefined
): NotificationMessage => {
  if (!notificationMessage)
    return {
      type: NotificationType.info,
      message: "",
    };

  return {
    ...(notificationMessage as NotificationMessage),
    message: "",
  };
};

export default Notifier;
