export class AsyncSelectData {
  title = "";
  tooltip?:string = undefined;
  value: any = "";
  extra?:any;
  labelMarker?:string;
  enabled? : boolean = true;
  constructor(init?: Partial<AsyncSelectData>) {
    Object.assign(this, init);
  }
}
