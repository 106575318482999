import { GridColDef } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Vehicle,
  useGetCustomerByIdLazyQuery,
  useGetTrucksByCustomerLazyQuery,
} from "../../../../../generated/graphql";
import { attachGridScrollEvent, flattenObj } from "../../../../helpers/misc";
import Notifier, {
  emptyNotification,
  NotificationMessage,
  NotificationType,
} from "../../../common/notifier/notifier";
import { SearchBarSearchParams } from "../../../common/search-bar/SearchBarSearchParams";
import Loader from "../../../common/loader/loader";
import OperationType from "../../../common/types/operation-type-enum";
import CustomTable from "../../../common/custom-table/CustomTable";
import KeyValue from "../../../common/types/keyValue";
import { LocalGridFooter } from "../../../common/grid-footer/grid-footer";
import ConfigurationShell from "src/app/pages/common/configuration-shell/ConfigurationShell";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import UpsertCustomerTruck from "./UpsertCustomerTruck";

const CustomerTrucks = () => {
    const { id } = useParams();
  let [notificationMessage, setNotificationMessage] =
    useState<NotificationMessage>();
  const [operationType, setOperationType] = useState(OperationType.read);
  const [truck, setTruck] = useState({} as Vehicle);
  const [rowsPerPage] = useState(50);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCursor, setPageCursor] = useState({ "0": undefined });
  const [sideBoxShown, setSideBoxShown] = useState(false);

  const { t } = useTranslation();

  const vehicleColumns: GridColDef[] = [
    { field: "vehicleNo", headerName: t("labels.vehicle-number") },
    { field: "eamId", headerName: t("labels.eam-id") },
    { field: "owner", headerName: t("labels.owner") },
    { field: "capacity", headerName: t("labels.capacity") },
    {
      field: "active",
      headerName: t("labels.active"),
      editable: false,
    },
  ];

  const searchItems: KeyValue[] = useMemo(
    () => [
      { key: "eamId", value: ("labels.eam-id") },
      { key: "vehicleNo", value: ("labels.veh-no") },
    ],
    []
  );
  const [searchBarParams, setSearchBarParams] = useState<SearchBarSearchParams>(
    {
      eventParams: undefined,
      selectedSearchItem: searchItems[0].key,
      text: "",
    }
  );

  const [customerTrucksArray, setCustomerTrucksArray] = useState<number[]>([]);

  const [getCustomerDetails, {data: customerByIdData, loading: loadingCustomerDetails}] = useGetCustomerByIdLazyQuery({ 
      variables: { customerId: Number(id) } 
  });

  const [
    getTrucks,
    {
      data: paginatedTrucks,
      loading: gettingTrucks,
      fetchMore: fetchMoreTrucks,
    },
  ] = useGetTrucksByCustomerLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

    // Call update on customer trucks on start of component or when a customer truck is upserted
    useEffect(() => {
      if(!loadingCustomerDetails)
        setCustomerTrucksArray(customerByIdData?.customerById?.vehicles.map(v=>v.id) ?? []);
    }, [getCustomerDetails, loadingCustomerDetails, customerByIdData?.customerById?.vehicles]);

  // if a row is clicked, set the operation to delete and open the side box
  const rowClicked = (param: Vehicle) => {
      setTruck(param);
      openSideBox();
      setOperationType(OperationType.delete);
      getCustomerDetails();
  };

  const getSearchBarParameters = useCallback(
    (params: SearchBarSearchParams) => {
      let data = {};

      searchItems.forEach((i) => {
        data[i.key] = params.selectedSearchItem === i.value ? params.text : "";
      });

      return data;
    },
    [searchItems]
  );

  const callGetTrucks = useCallback(
    (params: SearchBarSearchParams /*_cursor = undefined, _reset = false*/) => {
      getTrucks({
        variables: {
          customerId: Number(id),
          take: rowsPerPage,
          ...getSearchBarParameters(params),
        },
      });
    },
    [
      rowsPerPage,
      getTrucks,
      getSearchBarParameters,
      id
    ]
  );

  const searchBarSerched = (params: SearchBarSearchParams) => {
    setSearchBarParams(params);
    callGetTrucks(params);
  };

  const openSideBox = () => {
    setSideBoxShown(true);
  };

  const closeDetailBox = () => {
    setSideBoxShown(false);
  };


  const onHideNotification = () => {
    if (notificationMessage?.type === NotificationType.success) {
      setTimeout(() => {
        console.log("");
      }, 2);
    }
    setNotificationMessage(emptyNotification(notificationMessage));
  };

  useEffect(() => {
    if(id === undefined) return;

    callGetTrucks(searchBarParams);
  }, [
    getTrucks,
    callGetTrucks,
    searchBarParams,
    id
  ]);

  const pageChanged = (evt, page) => {
    let pageKey = page.toString();
    setPageNumber(Number(page));

    let endCursor = paginatedTrucks?.vehicles?.pageInfo.endCursor;
    if (!Object.keys(pageCursor).includes(pageKey)) {
      setPageCursor({
        ...pageCursor,
        [pageKey]: endCursor,
      });
    } else {
      endCursor = pageCursor[pageKey];
    }
    fetchMoreTrucks({
      variables: {
        customerId: Number(id),
        take: rowsPerPage,
        ...getSearchBarParameters(searchBarParams),
        cursor: endCursor,
      },
    });
  };

  const loadMore = () => {
    pageChanged(undefined, pageNumber + 1);
  };

  attachGridScrollEvent(
    paginatedTrucks?.vehicles?.nodes ?? [],
    gettingTrucks,
    paginatedTrucks?.vehicles?.totalCount ?? 0,
    loadMore
  );

  const newTruckForm = () => {
    setSideBoxShown(true);
    setTruck({} as Vehicle);
    getCustomerDetails();
    setOperationType(OperationType.create);
  };

  const GridComponent = () => {
    return (
      <>
        <div className="card__left__grid flex-grow">
          <CustomTable<Vehicle>
            orderBy="id"
            dataKey={"id"}
            data={
                paginatedTrucks?.vehicles?.nodes?.map((i) => {
                    return flattenObj(i);
                }) ?? []
            }
            columns={vehicleColumns}
            onRowClick={rowClicked}
          />

          {/* </Box> */}
        </div>
        <LocalGridFooter
          loadMore={loadMore}
          loading={gettingTrucks}
          loadedCount={paginatedTrucks?.vehicles?.nodes?.length ?? 0}
          totalCount={paginatedTrucks?.vehicles?.totalCount ?? 0}
        />
      </>
    );
  };

  const UpsertComponent = () => {
    return (
        <div
        className={`card__right overflow-y-auto w-1/3 2xl:w-1/4 p-1 rounded-md ${
          !sideBoxShown && "hide"
        }`}
      >
        <div className="card__right__form">
            <UpsertCustomerTruck
            customerId={Number(id)}
            trucks={customerTrucksArray}
            onClose={closeDetailBox}
            onStartEditClicked={() => {
                setOperationType(OperationType.update);
            }}
            onOperationSuccess={() => {
                closeDetailBox();
                callGetTrucks(searchBarParams);
                getCustomerDetails();
            }}
            operationType={operationType}
            selectedTruck={truck}
            />
        </div>
      </div>
    );
  };

  return (
    <div className="w-full py-2" style={{height:"calc(100vh - 12rem)"}}>
      <ConfigurationShell
        onSearch={searchBarSerched}
        searchItems={searchItems.map((i) => i.value)}
        sideBoxShown={sideBoxShown}
        title="modules.configuration.trucks.title"
        gridComponent={GridComponent}
        upsertComponent={UpsertComponent}
        onAddButtonClick={newTruckForm}
        previousPage="Customers"
        addButtonLabel={t("modules.configuration.customer-trucks.assign-vehicle")}
      />
      <Notifier
        notificationMessage={notificationMessage}
        onHideNotifier={onHideNotification}
      />
      {gettingTrucks && <Loader loaderSize={30} />}
    </div>
  );
};

export default CustomerTrucks;
