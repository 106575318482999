import { Outlet } from "react-router-dom";
import { MasterFooter } from "../MasterFooter/MasterFooter";
import { MasterHeader } from "../MasterHeader/MasterHeader";
import style from "./masterLayout.module.scss";

export const MasterLayout = () => {
  return (
    <main className="h-full w-full overflow-y-auto flex flex-col relative ">
      <MasterHeader />

      <article className={`${style.content} flex-grow p-4`}>
        <Outlet />
      </article>

      <div className=" static w-full ">
        <MasterFooter />
      </div>
    </main>
  );
};
