import { Navigate, Route, Routes } from "react-router-dom"
import { Logout } from "../pages/auth/components/Logout/Logout"
import { PrivateRoutes } from "./PrivateRoutes"
import AuthCallback from "../features/auth/AuthCallback"
import { Login } from "../pages/auth/components/Login/Login"
import App from "src/App"
import { useContext } from "react"
import AuthContext from "../contexts/auth-context"

export const AllRoutes = ()=>{

    const {authContextData} = useContext(AuthContext);

    return <Routes>
    <Route element={<App />}>
      {authContextData?.currentUser ? (
        <>
          <Route path="logout" element={<Logout />} />
          <Route path="/*" element={<PrivateRoutes />} />
          <Route index element={<Navigate to="/landing" />} />
        </>
      ) : (
        <>
          <Route path="*" element={<Login />} />
          <Route path="/auth" element={<AuthCallback />} />
        </>
      )}
    </Route>
  </Routes>
}