import { nanoid } from 'nanoid';
import pkceChallenge from 'pkce-challenge';

export type ChallengeSecrets = {
  codeChallenge: string;
  codeVerifier: string;
  state: string;
};

export const getChallengeSecrets = async(): Promise<ChallengeSecrets> => {
  const challenge = await pkceChallenge();
  const state = nanoid();

  return {
    codeChallenge: challenge.code_challenge,
    codeVerifier: challenge.code_verifier,
    state,
  };
};