import { LabelParams } from "./LabelParams";

const Label = ({
  text,
  hasUnderline = false,
  className = "",
  beDeep = false,
  style = {},
}: LabelParams) => {
  return (
    <>
      <h3
        style={style}
        className={`uppercase font-bold ${className} mb-2 mt-2 ${
          beDeep ? "text-gray-800" : "text-gray-500"
        }  ${hasUnderline ? " pb-3 border-b-2 border-gray-800" : ""}`}
      >
        {text}
      </h3>
    </>
  );
};

export default Label;
